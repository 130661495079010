import { useAppDispatch } from '@/store/hooks';
import { modalClosed, modalOpened } from '@/store/reducers/modal.reducer';
import { useEffect } from 'react';

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const ModalWrapper = ({ children, className }: Props) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(modalOpened());

    return () => {
      dispatch(modalClosed());
    };
  }, []);
  return <div className={`modal-screen ${className ?? ''}`}>{children}</div>;
};
