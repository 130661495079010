import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState, AppDispatch } from '../store';
import { organizationService } from '@/modules/organization/services/organization.service';
import type { Organization } from '@/types/';
import { addToaster } from './toaster.reducer';

export interface OrganizationState {
  organization: Organization | null;
}

// Define the initial state using that type
export const initialState: OrganizationState = {
  organization: null,
};

export const organizationSlice = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrganization: (state, action: PayloadAction<Organization | null>) => {
      state.organization = action.payload;
    },
  },
});
export const { setOrganization } = organizationSlice.actions;

// THUNKS
export const getOrganization = () => async (dispatch: AppDispatch) => {
  try {
    const org = await organizationService.getOwnOrganizations();
    if (!org) throw new Error('No organization found');
    dispatch(setOrganization(org));
  } catch (err) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('Error while getting organization:', err);
  }
};
export const getOrganizationById = (orgId: number) => async (dispatch: AppDispatch) => {
  try {
    const org = await organizationService.getOrganizationById(orgId);
    if (!org) throw new Error('No organization found with id: ' + orgId);

    return org;
  } catch (err) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('Error while getting organization by id:', err);
  }
};

// Other code such as selectors can use the imported `RootState` type
export const selectOrganization = (state: RootState) => state.organization.organization;
export const selectOrganizationId = (state: RootState) => state.organization.organization?.id ?? null;

export default organizationSlice.reducer;
