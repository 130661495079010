import { BasicConfiguration, FullConfiguration } from '@/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store';
import { configurationService } from '@/modules/common/services/configuration.service';
import { setFinishLoading, setLoading } from './status.reducer';
import { addToaster } from './toaster.reducer';

export interface ConfigurationState {
  configuration: BasicConfiguration | FullConfiguration | null;
  isLoading: boolean;
}

export const initialState: ConfigurationState = {
  configuration: null,
  isLoading: false,
};

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setConfiguration: (state, action: PayloadAction<BasicConfiguration | FullConfiguration | null>) => {
      state.configuration = action.payload;
    },
    setLocalLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export default configurationSlice.reducer;
export const { setConfiguration, setLocalLoading } = configurationSlice.actions;

export const getBasicConfiguration = () => async (dispatch: AppDispatch, getState: Function) => {
  const { status } = getState();
  const loadingId = 'getBasicConfiguration';

  if (status.ids.includes(loadingId)) return;

  try {
    dispatch(setLoading(loadingId));
    const res = await configurationService.getBasicAppConfiguration();

    dispatch(setConfiguration(res));
    return res;
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('While getting basic configuration:', err);
  } finally {
    dispatch(setFinishLoading(loadingId));
  }
};
export const getFullConfiguration = () => async (dispatch: AppDispatch, getState: Function) => {
  const { configuration } = getState();

  if (configuration.isLoading) return;

  try {
    dispatch(setLocalLoading(true));
    const res = await configurationService.getAppConfiguration();
    dispatch(setConfiguration(res));
    return res;
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('While getting full configuration:', err);
  } finally {
    dispatch(setLocalLoading(false));
  }
};

export const getCountryCode = () => async (dispatch: AppDispatch, getState: Function) => {
  const { status } = getState();
  const loadingId = 'getCountryCode';

  if (status.ids.includes(loadingId)) return;

  try {
    dispatch(setLoading(loadingId));
    const res = await configurationService.getCountryCode();

    return res;
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('While getting country code:', err);
  } finally {
    dispatch(setFinishLoading(loadingId));
  }
};

// SELECTORS
export const selectConfiguration = (state: RootState) => state.configuration.configuration;
