import { httpService } from '@/modules/common/services/http.service';
import { isPrimitive } from '@/modules/common/services/util.service';
import { Opportunity } from '@/types';
import { Filters } from '@/types/Filter';
import { TFunction } from 'i18next';

async function getOrgOpportunities(orgId: number, nextUrl?: string) {
  return httpService.get(`opportunities/search/?organization=${orgId}&hide_met_quota=true&without_join=true`, { fullUrl: nextUrl ? nextUrl : '' });
}

async function hasRecommendedOpportunities(orgId: number) {
  const res = await httpService.get(`opportunities/search/?organization=${orgId}&hide_met_quota=true&without_join=true&limit=1`);
  return res.count > 0;
}

async function getApprovedOpportunities(orgId: number, nextUrl?: string) {
  return httpService.get(`opportunities/approved/?organization=${orgId}`, { fullUrl: nextUrl ? nextUrl : '' });
}

async function getUserOpportunities(orgId: number, nextUrl?: string) {
  return httpService.get(`opportunities/active/?organization=${orgId}`, { fullUrl: nextUrl ? nextUrl : '' });
}

async function getCatagories(orgId: number) {
  return httpService.get(`opportunities/categories/list/?organizations=[${orgId}]&with_opportunities=true&limit=60`);
}

async function searchOpportunityByCatagory({
  orgId,
  catagoryId,
  nextUrl,
  filters,
  latitude,
  longitude,
  searchTerm,
  noLimit,
}: {
  orgId: number;
  catagoryId: number;
  filters?: string;
  nextUrl?: string | null;
  latitude?: number | null;
  longitude?: number | null;
  searchTerm?: string | null;
  noLimit?: boolean;
}) {
  let url = nextUrl ? nextUrl : `opportunities/search/?organization=${orgId}&without_join=true&user_status=1`;

  if (!noLimit && !nextUrl) {
    url += '&limit=10&offset=0';
  }

  if (catagoryId != -1) {
    url += `&categories=${catagoryId}`;
  }

  if (filters) {
    url += filters;
  }

  if (latitude != null && longitude != null) {
    url += `&user_location=${latitude},${longitude}`;
  }

  if (searchTerm) {
    url += `&search=${searchTerm}`;
  }

  return httpService.get(url, { fullUrl: nextUrl ? url : '' });
}

async function getOpportunityById(opportunityId: number): Promise<Opportunity> {
  return httpService.get(`opportunities/${opportunityId}/`);
}

async function getOpportunityContact(opportunityContactId: number): Promise<any> {
  return httpService.get(`contacts/${opportunityContactId}`);
}

async function joinOpportunity(opportunityId: number, organizationId: number) {
  const res = await httpService.post(`opportunities/${opportunityId}/request_user/`, { data: { organization: organizationId } });
  return res;
}
async function leaveOpportunity(opportunityId: number, organizationId: number, type: number, reason?: string) {
  const data = { organization: organizationId, leave_type: type, decline_reason: reason };
  const res = await httpService.post(`opportunities/${opportunityId}/leave_user/`, { data });
  return res;
}

function handleOpportunityFilter(filters: Filters) {
  let str = '';
  for (const section in filters) {
    const paramName = section;
    if (!paramName || filters[section] == null) continue;

    const filterValue = isPrimitive(filters[section])
      ? filters[section]
      : Object.keys(filters[section])
          .filter((key) => filters[section][key])
          .join(',');

    str += `&${paramName}=${filterValue}`;
  }

  return str;
}

function getFilterOptions(t: TFunction<'translation', undefined>, isAddLocationFilters: boolean) {
  const sortOptions = {
    title: 'sort',
    options: [
      { key: t('opportunity_filters_sort_by_date_added'), value: '-date_created' },
      { key: t('opportunity_filters_sort_by_opp_name'), value: 'name' },
    ],
    type: 'radio',
    filterKey: 'ordering',
  };

  const filterSections = [
    {
      title: t('vo_filter_title_time'),
      options: [
        { key: t('fragment_times_morning_0'), value: 0 },
        { key: t('fragment_times_noon_1'), value: 1 },
        { key: t('fragment_times_afternoon_2'), value: 2 },
        { key: t('fragment_times_evening_3'), value: 3 },
        { key: t('fragment_times_night_4'), value: 4 },
      ],
      type: 'checkbox',
      filterKey: 'times',
    },
    {
      title: t('vo_filter_title_days'),
      options: [
        { key: t('weekdays_sunday_0'), value: 0 },
        { key: t('weekdays_monday_1'), value: 1 },
        { key: t('weekdays_tuesday_2'), value: 2 },
        { key: t('weekdays_wednesday_3'), value: 3 },
        { key: t('weekdays_thursday_4'), value: 4 },
        { key: t('weekdays_friday_5'), value: 5 },
        { key: t('weekdays_saturday_6'), value: 6 },
      ],
      type: 'checkbox',
      filterKey: 'weekdays',
    },
    {
      title: t('vo_filter_title_commitment'),
      options: [
        { key: t('fragments_vo_type_long_term'), value: true },
        { key: t('vo_type_one_time'), value: false },
      ],
      type: 'radio',
      filterKey: 'long_term',
    },
  ];

  if (isAddLocationFilters) {
    sortOptions.options.unshift({ key: t('opportunity_filters_sort_by_distance'), value: 'distance,-is_internal,name' })
    filterSections.splice(2, 0, {
      title: t('vo_filter_title_search_radius'),
      options: [
        { key: t('opportunity_filters_radius').replace('%d', '5'), value: 5 },
        { key: t('opportunity_filters_radius').replace('%d', '10'), value: 10 },
        { key: t('opportunity_filters_radius').replace('%d', '20'), value: 20 },
        { key: t('opportunity_filters_radius').replace('%d', '50'), value: 50 },
      ],
      type: 'radio',
      filterKey: 'radius',
    })
  }

  const allFilters = [...filterSections, sortOptions];

  return { allFilters, filterSections, sortOptions };
}

function getTimeStr(val: number) {
  switch (val) {
    case 0:
      return 'fragment_times_morning_0';
    case 1:
      return 'fragment_times_noon_1';
    case 2:
      return 'fragment_times_afternoon_2';
    case 3:
      return 'fragment_times_evening_3';
    case 4:
      return 'fragment_times_night_4';
    default:
      return '';
  }
}

function getDayStr(val: number) {
  switch (val) {
    case 0:
      return 'weekdays_sunday_0';
    case 1:
      return 'weekdays_monday_1';
    case 2:
      return 'weekdays_tuesday_2';
    case 3:
      return 'weekdays_wednesday_3';
    case 4:
      return 'weekdays_thursday_4';
    case 5:
      return 'weekdays_friday_5';
    case 6:
      return 'weekdays_saturday_6';
    default:
      return '';
  }
}

export const opportunityService = {
  getOrgOpportunities,
  getApprovedOpportunities,
  getUserOpportunities,
  getCatagories,
  searchOpportunityByCatagory,
  handleOpportunityFilter,
  joinOpportunity,
  leaveOpportunity,
  getFilterOptions,
  getOpportunityById,
  getOpportunityContact,
  getTimeStr,
  getDayStr,
  hasRecommendedOpportunities
};
