/* LIBRARIES */
import { Suspense, useEffect, useState, lazy } from 'react';
import { Route, BrowserRouter as Router, Routes, Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { Capacitor } from '@capacitor/core';

/* PAGES */

const Welcome = lazy(() => import('@/modules/welcome/Welcome').then(module => ({ default: module.Welcome })));
const  MoeLogin = lazy(() => import('@/modules/auth/pages/MoeLogin').then(module => ({ default: module.MoeLogin })));
const  Home = lazy(() => import('@/modules/common/pages/Home').then(module => ({ default: module.Home })));
const  FeedPage = lazy(() => import('@/modules/posts/pages/FeedPage').then(module => ({ default: module.FeedPage })));
const  ReportPage = lazy(() => import('@/modules/report/pages/ReportPage').then(module => ({ default: module.ReportPage })));
const  ChatPage = lazy(() => import('@/modules/chat/pages/ChatPage').then(module => ({ default: module.ChatPage })));
const  ProfilePage = lazy(() => import('@/modules/profile/pages/ProfilePage').then(module => ({ default: module.ProfilePage })));
const  About = lazy(() => import('@/modules/common/pages/About').then(module => ({ default: module.About })));
const  OpportunityCatagoryList = lazy(() => import('@/modules/opportunity/pages/OpportunityCatagoryList').then(module => ({ default: module.OpportunityCatagoryList })));
const  OpportunityList = lazy(() => import('@/modules/opportunity/pages/OpportunityList').then(module => ({ default: module.OpportunityList })));
const  OpportunityPage = lazy(() => import('@/modules/opportunity/pages/OpportunityPage').then(module => ({ default: module.OpportunityPage })));
const  CommentList = lazy(() => import('@/modules/posts/pages/CommentList').then(module => ({ default: module.CommentList })));
const  NotFound = lazy(() => import('./modules/common/pages/404').then(module => ({ default: module.NotFound })));
const  StaticPathPage = lazy(() => import('./modules/common/pages/StaticPathPage').then(module => ({ default: module.StaticPathPage })));
const  ProfileEdit = lazy(() => import('./modules/profile/pages/ProfileEdit').then(module => ({ default: module.ProfileEdit })));
const  SettingsPage = lazy(() => import('./modules/settings/pages/SettingsPage').then(module => ({ default: module.SettingsPage })));

/* COMPONENTS */
import { ToasterList } from '@/modules/common/cmps/ToasterList';
import { ErrorModal } from './modules/common/cmps/ErrorModal';
import { AppFooter } from './modules/common/cmps/AppFooter';
import { ScrollToTop } from './modules/common/cmps/ScrollToTop';
import { NotificationController } from './modules/common/cmps/NotificationController';
import { LoginFakeUser } from './modules/auth/cmps/LoginFakeUser';
import { AppMobileStatus } from './modules/common/cmps/AppMobileStatus';
import { MobileUpdateController } from './modules/common/cmps/MobileUpdateController';
import { Loader } from '@/modules/common/cmps/Loader';

/* GUARDS */
import { RouteGuard } from '@/modules/common/guards/RouteGuard';

/* REDUCERS */
import { selectModalState } from '@/store/reducers/modal.reducer';
import { getUser, selectUser } from './store/reducers/user.reducer';
import { getOrganization, selectOrganization } from './store/reducers/organization.reducer';
import { isStatusLoading } from './store/reducers/status.reducer';
import { clearError, selectError } from './store/reducers/error.reducer';
import { getUserToken, selectIsLoggedIn, setIsLoggedIn } from './store/reducers/auth.reducer';
import { getFullConfiguration, selectConfiguration } from './store/reducers/configuration.reducer';
import { getLanguage, selectCurrentLanguage } from './store/reducers/language.reducer';

/* PROVIDERS */
import { WebSocketProvider } from '@/providers/WebSocketProvider';

/* HOOKS */
import { useMobile } from './hooks/useMobile';

/* SERVICES */
import { formatDate } from './modules/common/services/util.service';

function App() {
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const [isCheckedForToken, setIsCheckedForToken] = useState(false);
  
  /* AUTH */
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  
  const checkIsLoggedIn = async () => {
    const token = await dispatch(getUserToken());
    if (token) dispatch(setIsLoggedIn(true));
    else dispatch(setIsLoggedIn(false));
    
    setIsCheckedForToken(true);
  };
  
  useEffect(() => {
    dispatch(getLanguage());
    checkIsLoggedIn();
  }, []);

  /* DATA */
  const user = useAppSelector(selectUser);
  const org = useAppSelector(selectOrganization);
  const configuration = useAppSelector(selectConfiguration);

  
  
  const fetchData = async () => {
    dispatch(getUser());
    dispatch(getOrganization());
    dispatch(getFullConfiguration());
  };


  useEffect(() => {
    if (!isLoggedIn) return;
    // All initilazation after login should be here
    fetchData();
  }, [isLoggedIn]);

  const [canShowPage, setCanShowPage] = useState(false);

  useEffect(() => {
    if (!user || !org || !configuration) return;
    setCanShowPage(true);
  }, [user, org, configuration]);

  /* LANGUAGE */
  const lang = useAppSelector(selectCurrentLanguage);

  const setInitLanguage = async () => {
    i18n.changeLanguage(lang, () => {
      document.body.dir = i18n.dir();
    });
  };

  useEffect(() => {
    setInitLanguage();
  }, [lang]);

  /* MODAL */
  const isModalOpen = useAppSelector(selectModalState);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [isModalOpen]);

  /* ERROR */
  const errorToShow = useAppSelector(selectError);

  const closeErrorModal = () => {
    dispatch(clearError());
  };

  /* LOADER */
  const isLoading = useAppSelector(isStatusLoading);
  const isMobile = useMobile();
  if (import.meta.env.VITE_FAKE_USER && !isLoggedIn) return <LoginFakeUser setIsCheckedForToken={setIsCheckedForToken} />;
  if (!isCheckedForToken || isLoggedIn === null || (isLoggedIn && !canShowPage)) return <Loader />;

  // Maintenance popup
  if (configuration && configuration.show_maintenance_popup && configuration.maintenance_popup_message) {
    let text = t(configuration.maintenance_popup_message);

    if (configuration.maintenance_popup_end_date) {
      text = text.replace('%s', formatDate(new Date(configuration.maintenance_popup_end_date)));
    }

    return <ErrorModal text={text} title={t('maintenance_popup_title')} buttons={<></>} />;
  }

  return (
    <WebSocketProvider>
      {Capacitor.isNativePlatform() && (
        <>
          <NotificationController />
          <MobileUpdateController />
          {isLoggedIn && (
            <>
              <AppMobileStatus />
            </>
          )}
        </>
      )}
    <Suspense fallback={<Loader />}>
      <Router>
        <ScrollToTop />
        <section className="main-app">
          <Routes>
            <Route path="/welcome" element={<Welcome />} />
            <Route path="/auth/edu/callback" element={<MoeLogin />} />
            <Route path="/home" element={<RouteGuard component={Home} isAuth={isLoggedIn} />}>
              <Route path="" element={<Navigate to={configuration?.mobile_app_home_page || 'report'} />} />
              <Route path="feed/comment/:postId" element={<CommentList />} />
              <Route path="feed" element={<FeedPage />} />
              <Route path="opportunity/catagory/:catagoryId" element={<OpportunityList />} />
              <Route path="opportunity/catagory" element={<OpportunityCatagoryList />} />
              <Route path="opportunity/:opportunityId" element={<OpportunityPage />} />
              <Route path="report" element={<ReportPage />} />
              <Route path="chat" element={<ChatPage />} />
              <Route path="profile" element={<ProfilePage />} />
              <Route path="profile/edit" element={<ProfileEdit />} />
            </Route>
            <Route path="/settings" element={<SettingsPage />} />
            <Route path="about" element={<About />} />
            <Route path="privacy-policy" element={<StaticPathPage pageName="privacy-policy" />} />
            <Route path="terms-of-use" element={<StaticPathPage pageName="terms-of-use" />} />
            <Route path="/" element={isLoggedIn ? <Navigate to="/home" /> : <Navigate to="/welcome" />} />
            <Route path="*" element={<NotFound />} />
          </Routes>

          {!isMobile && <AppFooter />}
        </section>
        {errorToShow ? (
          <ErrorModal
            text={errorToShow.text}
            title={errorToShow.title}
            onClose={closeErrorModal}
            buttons={
              <button onClick={closeErrorModal} className="btn default">
                Ok
              </button>
            }
          />
        ) : null}
        <ToasterList />
        {isLoading && <Loader />}
      </Router>
      </Suspense>
    </WebSocketProvider>
  );
}

export default App;
