import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppDispatch, RootState } from '../store';
import { authService } from '@/modules/auth/services/auth.service';
import { ERROR_TYPES } from '@/modules/common/services/error.handling.service';
import { addToaster } from './toaster.reducer';
import { sentryService } from '@/modules/common/services/sentry.service';

export interface AuthState {
  isLoggedIn: boolean;
  isLoading?: boolean;
  token?: string;
}

export const initialState: AuthState = {
  isLoggedIn: false,
  isLoading: false,
  token: '',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.isLoggedIn = action.payload;
    },
    setAuthLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    clearData: (state) => {
      state.isLoggedIn = false;
      state.isLoading = false;
      state.token = '';
      sentryService.setUser(null);
    },
  },
});

export default authSlice.reducer;
export const { setIsLoggedIn, setAuthLoading, clearData, setToken } = authSlice.actions;

export const login = (userToken: string) => async (dispatch: AppDispatch) => {
  dispatch(setAuthLoading(true));
  try {
    const resp = await authService.loginMoeUser(userToken);

    if (resp?.key && resp?.user) {
      authService.saveUserToken(resp.key);
      authService.saveUser(resp.user);
      sentryService.setUser(resp.user);

      dispatch(setToken(resp.key));
      dispatch(setIsLoggedIn(true));
      return resp?.user;
    }
  } catch (err: any) {
    if (Object.values(ERROR_TYPES).includes(err?.type)) {
      throw err;
    } else {
      dispatch(
        addToaster({
          type: 'error',
          title: 'error_title',
          text: 'oops_smthng_went_wrong',
        })
      );
    }
    console.log('Error while logging in:', err.message);
  } finally {
    dispatch(setAuthLoading(false));
  }
};
export const emailLogin = (userCreds: { email: string; password: string }) => async (dispatch: AppDispatch) => {
  dispatch(setAuthLoading(true));
  try {
    const resp = await authService.loginEmailUser({ username: userCreds.email, password: userCreds.password });

    if (resp?.key && resp?.user) {
      authService.saveUserToken(resp.key);
      authService.saveUser(resp.user);
      sentryService.setUser(resp.user);

      dispatch(setToken(resp.key));
      dispatch(setIsLoggedIn(true));
      return resp?.user;
    }
  } catch (err: any) {
    if (err?.type === ERROR_TYPES.AUTH) {
      throw err;
    } else {
      dispatch(
        addToaster({
          type: 'error',
          title: 'error_title',
          text: 'oops_smthng_went_wrong',
        })
      );
    }
    console.log('Error while logging in with email:', err.message);
  } finally {
    dispatch(setAuthLoading(false));
  }
};

export const logout = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(clearData());
    await authService.logout();
  } catch (err: any) {
    console.log('Error while logging out:', err.message);
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
  }
};
export const logoutClientOnly = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(clearData());
    await authService.logoutClientOnly();
  } catch (err: any) {
    console.log('Error while logging out client only:', err.message);
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
  }
};

export const clearAuthData = () => async (dispatch: AppDispatch) => {
  dispatch(clearData());
  authService.clearData();
};

export const getUserToken = () => async (dispatch: AppDispatch) => {
  try {
    const userToken = await authService.getUserToken();

    if (userToken) {
      dispatch(setToken(userToken));
    }

    return userToken;

  } catch (err: any) {
    console.log('Error while getting user token:', err.message);

    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'invalid_token_title',
      })
    );
  }
};

export const selectIsLoggedIn = (state: RootState) => state.auth.isLoggedIn;
export const selectUserToken = (state: RootState) => state.auth.token;
