import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface RouteState {
  prevRoute: string | null;
}

export const initialState: RouteState = {
  prevRoute: null,
};

export const routeSlice = createSlice({
  name: 'route',
  initialState,
  reducers: {
    setPrevRoute: (state, action: PayloadAction<string>) => {
      state.prevRoute = action.payload;
    },
  },
});

export default routeSlice.reducer;
export const { setPrevRoute } = routeSlice.actions;
