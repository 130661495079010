import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { AppDispatch, RootState } from '../store';
import { Message, Room } from '@/types/Chat';
import { setFinishLoading, setLoading } from './status.reducer';
import { chatService, CHAT_ROOMS } from '@/modules/chat/services/chat.service';
import { SocketMessage } from '@/modules/common/services/ws.service';
import { addToaster } from './toaster.reducer';
import { selectBlockedIds } from './user.reducer';

export interface ChatState {
  rooms: Room[];
  roomCount: number;
  nextRoomsUrl: string | null;

  messages: Message[];
  messageCount: number;
  nextMessagesUrl: string | null;
  selectedRoomId: number | null;
}

export const initialState: ChatState = {
  rooms: [],
  roomCount: 0,
  nextRoomsUrl: '',

  messages: [],
  messageCount: 0,
  nextMessagesUrl: '',

  selectedRoomId: null,
};

// reducer functions
export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setChatRooms: (state, action: PayloadAction<Room[] | null>) => {
      state.rooms = action.payload || [];
    },
    setRoomCount: (state, action: PayloadAction<number>) => {
      state.roomCount = action.payload;
    },
    setNextRoomsUrl: (state, action: PayloadAction<string | null>) => {
      state.nextRoomsUrl = action.payload;
    },
    clearRoomUnreadCount: (state, action: PayloadAction<number>) => {
      const roomId = action.payload;
      const upadtedRooms = state.rooms.map((room) => (room.id === roomId ? { ...room, unread_counter: 0 } : room));

      state.rooms = upadtedRooms;
    },
    incerementRoomUnreadCount: (state, action: PayloadAction<number>) => {
      const roomId = action.payload;
      const upadtedRooms = state.rooms.map((room) => (room.id === roomId ? { ...room, unread_counter: (room.unread_counter ?? 0) + 1 } : room));

      state.rooms = upadtedRooms;
    },
    updateLastMessage: (state, action: PayloadAction<Message>) => {
      const message = action.payload;
      const upadtedRooms = state.rooms.map((room) => (room.id === message.room_id ? { ...room, last_message: message } : room));

      state.rooms = upadtedRooms;
    },
    setSelectedRoomId: (state, action: PayloadAction<number | null>) => {
      state.selectedRoomId = action.payload;
    },

    // MESSAGES

    setMessages: (state, action: PayloadAction<Message[] | null>) => {
      const messages = action.payload || [];
      state.messages = [...messages, ...state.messages] || [];
    },
    setMessageCount: (state, action: PayloadAction<number>) => {
      state.roomCount = action.payload;
    },
    setNextMessagesUrl: (state, action: PayloadAction<string | null>) => {
      state.nextMessagesUrl = action.payload;
    },
    resetMessages: (state) => {
      state.messages = [];
      state.messageCount = 0;
      state.nextMessagesUrl = '';
    },
    appendMessage: (state, action: PayloadAction<Message>) => {
      const message = action.payload;
      state.messages = [...state.messages, message];
    },
  },
});

export const {
  setChatRooms,
  setRoomCount,
  setNextRoomsUrl,
  clearRoomUnreadCount,
  incerementRoomUnreadCount,
  setMessages,
  setMessageCount,
  setNextMessagesUrl,
  resetMessages,
  appendMessage,
  updateLastMessage,
  setSelectedRoomId,
} = chatSlice.actions;

export default chatSlice.reducer;

const resetChatRooms = () => async (dispatch: AppDispatch) => {
  dispatch(setChatRooms([]));
  dispatch(setNextRoomsUrl(null));
  dispatch(setRoomCount(0));
};

export const getChatRooms =
  (reset: boolean = false) =>
  async (dispatch: AppDispatch, getState: Function) => {
    const { chat, status } = getState();
    const loadingId = 'getChatRooms';

    try {
      if (!reset && chat.rooms && chat.rooms.length && !chat.nextRoomsUrl) return;
      if (status.ids.includes(loadingId)) return;
      dispatch(setLoading(loadingId));

      if (reset) await dispatch(resetChatRooms());

      const nextUrl = reset ? '' : chat.nextRoomsUrl;
      const { results, count, next } = await chatService.getChatRooms(nextUrl);

      if (!results) throw new Error('No chat rooms found');

      dispatch(setChatRooms([...getState().chat.rooms, ...results]));
      dispatch(setRoomCount(count));

      if (next && next === chat.nextRoomsUrl) dispatch(setNextRoomsUrl(null));
      else dispatch(setNextRoomsUrl(next));
    } catch (err: any) {
      dispatch(
        addToaster({
          type: 'error',
          title: 'error_title',
          text: 'oops_smthng_went_wrong',
        })
      );
      console.log('Error while getting chat rooms:', err);
    } finally {
      dispatch(setFinishLoading(loadingId));
    }
  };

export const getRoomMessages = (roomId: number) => async (dispatch: AppDispatch, getState: Function) => {
  const { chat, status } = getState();
  const loadingId = 'getRoomMessages';
  try {
    if (chat.messages && chat.messages.length && !chat.nextMessagesUrl) return;
    if (status.ids.includes(loadingId)) return;

    dispatch(setLoading(loadingId));
    const { results, count, next } = await chatService.getChatMessageHistory(roomId, chat.nextMessagesUrl);

    dispatch(setMessages(results.reverse()));
    dispatch(setMessageCount(count));

    if (next && next === chat.nextMessagesUrl) dispatch(setNextMessagesUrl(null));
    else dispatch(setNextMessagesUrl(next));
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('Error while getting chat rooms:', err);
  } finally {
    dispatch(setFinishLoading(loadingId));
  }
};

export const updateChatMessage = (message: SocketMessage) => async (dispatch: AppDispatch, getState: Function) => {
  const { chat, user } = getState();
  try {
    if (user.blockedIds.includes(message.user?.id)) return;

    const parsedMessage = chatService.extractChatMessageFromSocketMessage(message);
    const messageRoomId = parsedMessage.room_id!;

    if (chat.selectedRoomId && messageRoomId === chat.selectedRoomId) {
      dispatch(appendMessage(parsedMessage));
      await chatService.markAsRead(messageRoomId);
    } else {
      dispatch(incerementRoomUnreadCount(messageRoomId));
    }
    dispatch(updateLastMessage(parsedMessage));
    return parsedMessage;
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('Error while updating chat message:', err);
  }
};

export const selectChatRooms = (state: RootState) => state.chat.rooms;
export const selectSelectedRoomId = (state: RootState) => state.chat.selectedRoomId;
export const selectMessages = (state: RootState) => state.chat.messages;
export const selectUnblockedMessages = createSelector([selectMessages, selectBlockedIds], (messages, blockedIds) =>
  messages.filter((m: Message) => m.user && !blockedIds.includes(m.user.id))
);

// SORTED ROOMS
export const selectChatRoomsSorted = createSelector([selectChatRooms, selectSelectedRoomId], (rooms, _selectedRoomId) =>
  [...rooms].sort((a, b) => {
    // sort by type (type tribu)
    if (a.type === CHAT_ROOMS.TRIBU && b.type !== CHAT_ROOMS.TRIBU) return -1;
    if (a.type !== CHAT_ROOMS.TRIBU && b.type === CHAT_ROOMS.TRIBU) return 1;

    // sort by type (type organization)
    if (a.type === CHAT_ROOMS.ORGANIZATION && b.type !== CHAT_ROOMS.ORGANIZATION) return -1;
    if (a.type !== CHAT_ROOMS.ORGANIZATION && b.type === CHAT_ROOMS.ORGANIZATION) return 1;

    // Then, sort by the most recent last_message
    const lastMessageA = a.last_message?.date_created || '0000-00-00T00:00:00';
    const lastMessageB = b.last_message?.date_created || '0000-00-00T00:00:00';

    // Since we want the most recent message first, compare B to A
    return lastMessageB.localeCompare(lastMessageA);
  })
);
