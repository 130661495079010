import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { useEffect, useRef } from 'react';
import { END_MOBILE_SESSION, START_MOBILE_SESSION, analyticsService } from '../services/analytics.service';
import { useAppSelector } from '@/store/hooks';
import { selectUser } from '@/store/reducers/user.reducer';
import { eventBus, ACTIONS } from '@/modules/common/services/event-bus.service';

interface EventData {
  event_date: string;
  user_id?: number;
  duration?: number;
}

export const AppMobileStatus = () => {
  const startTime = useRef<number | null>(null);
  const user = useAppSelector(selectUser);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return;

    App.addListener('appStateChange', ({ isActive }: { isActive: boolean }) => {
      if (isActive) {
        startTime.current = Date.now();

        const eventData: EventData = { event_date: new Date().toISOString() };
        if (user && user.pk != null) {
          eventData['user_id'] = user.pk;
        }

        analyticsService.postAnalyticsEvent(START_MOBILE_SESSION, eventData);
        eventBus.emit(ACTIONS.APP_IS_ACTIVE);
      } else {
        if (!startTime.current) return;
        const sessionLength = Date.now() - startTime.current;

        const eventData: EventData = { event_date: new Date().toISOString(), duration: Math.trunc(sessionLength / 1000) };
        if (user && user.pk != null) {
          eventData['user_id'] = user.pk;
        }

        analyticsService.postAnalyticsEvent(END_MOBILE_SESSION, eventData);
      }
    });

    return () => {
      App.removeAllListeners();
    };
  }, []);

  return null;
};
