import { httpService } from '@/modules/common/services/http.service';
import { storageService } from '@/modules/common/services/storage';

const ORGANIZATION = 'organization';

export const getOwnOrganizations = async () => {
  let org;
  org = await storageService.load(ORGANIZATION);
  if (!org) {
    const resp = await httpService.get('organizations/get_own_organizations/?user_status=1');
    if (resp.results && resp.results.length) {
      org = resp.results[0];
      await storageService.save(ORGANIZATION, org);
    }
  }

  return org;
};

function clearOrganization() {
  storageService.remove(ORGANIZATION);
}

async function getOrganizationById(orgId: number) {
  return httpService.get(`organizations/${orgId}/`);
}

export const organizationService = {
  getOwnOrganizations,
  clearOrganization,
  getOrganizationById,
};
