import { userService } from '@/modules/user/services/user.service';
import { Organization, User } from '@/types';

export async function getUserDetails(user: User, org?: Organization | null) {
  const roles = await getUserRoles();
  return {
    name: `${user.first_name} ${user.last_name}`,
    email: user.email,
    Phone: user.phone_number,
    'ORG ID': org?.id?.toString() ?? '',
    'ORG Name': org?.name ?? '',
    Role: roles,
    'USER ID': user.pk.toString(),
  };
}

async function getUserRoles() {
  const roles = await userService.getUserRoles();

  let userRoles = ['volunteer'];
  userRoles.push(roles.group_admin ? 'group admin' : '');
  userRoles.push(roles.limited_group_admin ? 'limited group admin' : '');
  userRoles.push(roles.organization_admin ? 'org admin' : '');
  userRoles.push(roles.limited_organization_admin ? 'limited org admin' : '');
  userRoles.push(roles.municipality_admin ? 'municipality admin' : '');
  userRoles.push(roles.region_admin ? 'region admin' : '');
  userRoles.push(roles.opportunity_admin ? 'opportunity admin' : '');
  userRoles.push(roles.tribu_admin ? 'tribu admin' : '');

  return userRoles.filter((r) => r).join(', ');
}
