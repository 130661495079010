import { config } from '@/config';
import { authService } from '@/modules/auth/services/auth.service';
import { createWebSocketClient, WebSocketClient, SocketMessage, WebSocketClientConfig } from './ws/ws.client';
export * from './ws/message.action';
export type { WebSocketClient, WebSocketClientConfig, SocketMessage } from './ws/ws.client';

let wsClient: WebSocketClient | null = null;
let disposableToken: string | null = null;

export async function initSocket(config?: WebSocketClientConfig): Promise<WebSocketClient> {
  const url = await getConnectionURL();
  if (!wsClient) {
    wsClient = createWebSocketClient(url, config);
  }
  if (wsClient) wsClient.connect();

  return wsClient;
}

export function disconnectSocket(): void {
  if (!wsClient) return;
  console.log('Disconnecting socket...');
  wsClient.disconnect();
}

export function sendMessage(messageObj: SocketMessage): void {
  if (!wsClient) return;
  wsClient.sendMessage(messageObj);
}

async function getConnectionURL() {
  if (disposableToken) return `${config.chetWebsocketUrl}${disposableToken}`;
  else {
    const token = await authService.getWSToken();
    disposableToken = token;
    return `${config.chetWebsocketUrl}${token}`;
  }
}
