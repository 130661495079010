import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState, AppDispatch } from '../store';
import { opportunityService } from '@/modules/opportunity/services/opportunity.service';
import type { Catagory } from '@/types/';
import { setFinishLoading, setLoading } from './status.reducer';
import { addToaster } from './toaster.reducer';

const allCatagoriesOpt = {
  id: -1,
  name: 'categories_all_item_text',
  organization: null,
  image: null,
};

export interface OportunitynCatagoryState {
  catagories: Catagory[] | null;
  catagoryCount: number;
}

// Define the initial state using that type
export const initialState: OportunitynCatagoryState = {
  catagories: null,
  catagoryCount: 0,
};

export const opportunitySlice = createSlice({
  name: 'catagory',
  initialState,
  reducers: {
    setCatagories: (state, action: PayloadAction<Catagory[] | null>) => {
      state.catagories = action.payload;
    },
    setTotalCatagoryCount: (state, action: PayloadAction<number>) => {
      state.catagoryCount = action.payload;
    },
  },
});

// ACTIONS
export const { setCatagories, setTotalCatagoryCount } = opportunitySlice.actions;

// THUNKS
export const getCatagories = (orgId: number) => async (dispatch: AppDispatch, getState: Function) => {
  const { status } = getState();
  const loadingId = 'getCatagories';

  if (status.ids.includes(loadingId)) return;

  try {
    dispatch(setLoading(loadingId));

    const { results, count } = await opportunityService.getCatagories(orgId);
    if (!results) throw new Error('No opportunity catagories found');
    dispatch(setCatagories([allCatagoriesOpt, ...results]));
    dispatch(setTotalCatagoryCount(count + 1));

    dispatch(setFinishLoading(loadingId));
  } catch (err) {
    dispatch(setFinishLoading(loadingId));
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('Error while getting opportunity catagories:', err);
  }
};

// Other code such as selectors can use the imported `RootState` type
export const selectOpportunityCatagory = (state: RootState) => state.catagory.catagories;
export const selectOpportunityCatagoryCount = (state: RootState) => state.catagory.catagoryCount;
export const selectCatagoryById = (state: RootState, catagoryId: number) => state.catagory.catagories?.find((catagory: Catagory) => catagory.id === catagoryId);

export default opportunitySlice.reducer;

