import Axios from 'axios';
import { config } from '@/config';
import { authService } from '@/modules/auth/services/auth.service';
import { errorService } from './error.handling.service';
import { languageService } from './language.service';

interface Options {
  headers?: {};
  data?: {} | null;
  params?: {};
  method?: string;
  withToken?: boolean;
  fullUrl?: string;
  withoutLanguage?: boolean;
}

const defaultOptions: Options = { method: 'get', data: null, params: {}, headers: {}, withToken: true, fullUrl: '', withoutLanguage: false };

const axios = Axios.create({
  withCredentials: true,
});

async function getAuthHeader() {
  return {
    Authorization: 'Token ' + (await authService.getUserToken()),
  };
}

async function getLanguageHeader() {
  return {
    'Accept-Language': (await languageService.getCurrentLanguage()) || 'en',
  };
}

export const httpService = {
  ajax,
  get(endpoint: string, options?: Options) {
    return ajax(endpoint, { method: 'GET', ...options });
  },
  post(endpoint: string, options?: Options) {
    return ajax(endpoint, { method: 'POST', ...options });
  },
  put(endpoint: string, options?: Options) {
    return ajax(endpoint, { method: 'PUT', ...options });
  },
  delete(endpoint: string, options?: Options) {
    return ajax(endpoint, { method: 'DELETE', ...options });
  },
  patch(endpoint: string, options?: Options) {
    return ajax(endpoint, { method: 'PATCH', ...options });
  },
};

async function ajax(endpoint: string, options?: Options) {
  options = { ...defaultOptions, ...options };
  const { method, data, params, headers, withToken, fullUrl, withoutLanguage } = options;

  try {
    const res = await axios({
      url: fullUrl ? fullUrl : `${config.dmsBaseURL}${endpoint}`,
      method,
      data,
      params,
      headers: {
        ...(withToken ? await getAuthHeader() : {}),
        ...(withoutLanguage ? {} : await getLanguageHeader()),
        ...headers,
      },
    });
    return res.data;
  } catch (err: any) {
    console.log('http service err:', err);
    return errorService.errorHandler(err);
  }
}
