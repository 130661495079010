import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { useIntercom } from '@/hooks/useIntercome';

export const AppFooter = () => {
  const { t } = useTranslation();
  const { showIntercom } = useIntercom();

  const year = new Date().getFullYear();

  return (
    <section className="app-footer-container">
      <main className="container wide">
        <div className="copy-rights">&#169;{year} MOE</div>
        <nav className="links">
          <Link to="/about">{t('fragment_about_us_title')}</Link>
          <span> / </span>
          <button onClick={() => showIntercom()}>{t('report_a_bug_title')}</button>
          <span> / </span>
          <Link to="/privacy-policy">{t('privacy_policy_title')}</Link>
          <span> / </span>
          <Link to="/terms-of-use">{t('terms_of_use_title')}</Link>
        </nav>
      </main>
    </section>
  );
};
