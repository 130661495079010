import { config } from '@/config';
import { getUserDetails } from './util';
import { languageService } from '../language.service';
import { Organization, User } from '@/types';

declare global {
  interface Window {
    Intercom: any;
    intercomSettings: any;
    attachEvent: any;
  }
}
interface IFunctionWithProperties {
  (): void;
  q: any[];
  c: (args: any) => void;
}

async function initializeIntercom() {
  intercomScriptInit();
  const intercomConfig = await getIntercomWebConfig();
  window.Intercom('boot', intercomConfig);
}

async function getIntercomWebConfig(user?: User | null, org?: Organization | null) {
  const lang = await languageService.getCurrentLanguage();

  const defaultConfig = {
    app_id: config.intercomAppid,
    language_override: lang,
    alignment: languageService.isRtl(lang) ? 'right' : 'left',
    hide_default_launcher: true,
    is_web_app_user: true,
    Platform: 'webapp',
    'ORG Type': 'MOE',
  };
  if (!user) {
    return defaultConfig;
  }

  const userDetails = await getUserDetails(user, org);
  return {
    ...defaultConfig,
    ...userDetails,
  };
}


async function showIntercom(user?: User | null, org?: Organization | null) {
  const intercomConfig = await getIntercomWebConfig(user, org);
  window.Intercom('update', intercomConfig);
  window.Intercom('show');
}

async function shutdownIntercom() {
  window.Intercom('shutdown');
}

export default { initializeIntercom, showIntercom, shutdownIntercom };

function intercomScriptInit() {
  var w = window;
  var ic = w.Intercom;
  if (typeof ic === 'function') {
    ic('reattach_activator');
    ic('update', w.intercomSettings);
  } else {
    var d = document;
    var i = <IFunctionWithProperties>function () {
      i.c(arguments);
    };
    i.q = [];
    i.c = function (args: any[]) {
      i.q.push(args);
    };
    w.Intercom = i;
    var l = function () {
      var s = d.createElement('script');
      s.type = 'text/javascript';
      s.async = true;
      s.src = `https://widget.intercom.io/widget/${config.intercomAppid}`;
      var x = d.getElementsByTagName('script')[0];
      x.parentNode?.insertBefore(s, x);
    };
    if (document.readyState === 'complete') {
      l();
    } else if (w.attachEvent) {
      w.attachEvent('onload', l);
    } else {
      w.addEventListener('load', l, false);
    }
  }
}
