import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState, AppDispatch } from '../store';
import { authService } from '@/modules/auth/services/auth.service';
import type { User } from '@/types/';
import { userService } from '@/modules/user/services/user.service';
import { setFinishLoading, setLoading } from './status.reducer';
import { addToaster } from './toaster.reducer';
import { BlockedUser } from '@/types/User';

export interface UserState {
  user: User | null;
  blockedIds: number[];
  reportReasons: Record<string, string | number>[];
}

// Define the initial state using that type
export const initialState: UserState = {
  user: null,
  blockedIds: [],
  reportReasons: [],
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;
    },
    setBlockedIds: (state, action: PayloadAction<number[]>) => {
      state.blockedIds = action.payload;
    },
    setReportReasons: (state, action: PayloadAction<Record<string, string | number>[]>) => {
      state.reportReasons = action.payload;
    },
  },
});
export const { setUser, setBlockedIds, setReportReasons } = userSlice.actions;

// THUNKS
export const getUser = () => async (dispatch: AppDispatch) => {
  try {
    const user = await authService.getUser();
    if (!user) throw new Error('No user found');
    dispatch(setUser(user));
  } catch (err) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('Error while getting user:', err);
  }
};

export const blockUser = (userId: number) => async (dispatch: AppDispatch, getState: Function) => {
  const { user } = getState();
  const loadingId = 'blockUser';
  try {
    dispatch(setLoading(loadingId));
    await userService.blockUser(userId);
    dispatch(setBlockedIds([userId, ...user.blockedIds]));
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log(`Error while blocking user ${userId}: ${err}`);
  } finally {
    dispatch(setFinishLoading(loadingId));
  }
};
export const reportUser = (userId: number, reason: number) => async (dispatch: AppDispatch) => {
  const loadingId = 'reportUser';
  try {
    dispatch(setLoading(loadingId));
    return userService.reportUser(userId, reason);
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log(`Error while reporting user ${userId}: ${err}`);
  } finally {
    dispatch(setFinishLoading(loadingId));
  }
};

export const getBlockedUsers = () => async (dispatch: AppDispatch) => {
  try {
    const blockedUsers = await userService.getBlockedUsers();
    dispatch(setBlockedIds(blockedUsers.map((u: BlockedUser) => u.id)));
    return blockedUsers;
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log(`Error while getting blocked users: ${err}`);
  } finally {
  }
};

export const unBlockUsers = (userIds: number[]) => async (dispatch: AppDispatch, getState: Function) => {
  const { user } = getState();
  const loadingId = 'unBlockUsers';
  try {
    dispatch(setLoading(loadingId));
    await userService.unBlockUsers(userIds);
    dispatch(setBlockedIds(user.blockedIds.filter((id: number) => !userIds.includes(id))));
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log(`Error while unblocking users: ${err}`);
  } finally {
    dispatch(setFinishLoading(loadingId));
  }
};

export const getReportUserReasons = () => async (dispatch: AppDispatch) => {
  try {
    const reasons = await userService.getReportUserReasons();
    dispatch(setReportReasons(reasons));
    return reasons;
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('Error while getting report user reasons:', err);
  }
};

// Other code such as selectors can use the imported `RootState` type
export const selectUser = (state: RootState) => state.user.user;
export const selectBlockedIds = (state: RootState) => state.user.blockedIds;

export default userSlice.reducer;
