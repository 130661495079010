import { httpService } from '@/modules/common/services/http.service';
import type { Report, ReportApproveData } from '@/types/Report';

export async function getReports(userId: number, orgId: number, nextUrl?: string) {
  return httpService.get(`opportunities/attendance_reports/?status=1,3&ordering=status,-date_created&user=${userId}&organizations=${orgId}`, {
    fullUrl: nextUrl ? nextUrl : '',
  });
}

export async function deleteReport(reportId: number) {
  return await httpService.delete(`opportunities/attendance_reports/${reportId}/`);
}

export interface CreateReportProps {
  userId: number;
  orgId: number;
  oppId: number;
  date: Date;
  startTime: string;
  endTime: string;
  text?: string;
  reportId?: number;
}

export interface ReportData {
  user_id: number;
  organization: number;
  start_time: string;
  end_time: string;
  date: Date;
  opportunity: number;
  free_text?: string;
}

export async function saveReport({ reportId, userId, orgId, oppId, date, startTime, endTime, text }: CreateReportProps) {
  if (startTime.length === 5) startTime = startTime + ':00';
  if (endTime.length === 5) endTime = endTime + ':00';

  const data: ReportData = {
    user_id: userId,
    organization: orgId,
    start_time: startTime,
    end_time: endTime,
    date: date,
    opportunity: oppId,
  };
  if (text) {
    data.free_text = text;
  }

  if (reportId != null) {
    return httpService.patch(`opportunities/attendance_reports/${reportId}/`, { data });
  } else {
    return httpService.post(`opportunities/attendance_reports/`, { data });
  }
}

export async function approveReport({ reportIds, name, israelID, base64Image }: ReportApproveData) {
  const reportIdsStr = reportIds && reportIds.map((id) => String(id));
  const data = {
    ids: reportIdsStr,
    name,
    pin: israelID,
    signature_image: base64Image,
  };
  return await httpService.post(`opportunities/attendance_reports/multi-approve/`, { data });
}

function prepareReportData(report: Report) {
  return {
    date: report.date,
    startTime: report.start_time,
    endTime: report.end_time,
    image: report.opportunity?.image,
    isGroup: report.opportunity?.count_personally == false,
    opportunityName: report.opportunity?.name,
    id: report.id,
    status: report.status,
  };
}

export const reportService = {
  getReports,
  deleteReport,
  saveReport,
  approveReport,

  prepareReportData,
};
