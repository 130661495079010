import { format, isToday, isYesterday, parseISO } from 'date-fns';
import { useTranslation } from 'react-i18next';

export function getRelativeDate(createAt: string) {
  const { t } = useTranslation();
  const now = new Date();
  const utcTimeOffset = now.getTimezoneOffset() / 60;

  let creationDate = parseISO(createAt);
  creationDate.setHours(creationDate.getHours() - utcTimeOffset);

  if (isToday(creationDate)) {
    return format(creationDate, 'HH:mm');
  } else if (isYesterday(creationDate)) {
    return t('fragment_post_comments_date_time_yesterday');
  } else {
    return format(creationDate, 'dd-MM-yyyy');
  }
}

export function makeId(length = 5) {
  var text = '';
  var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (var i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }
  return text;
}

export function isPrimitive(value: any): boolean {
  return typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';
}

export const formatClockTime = (time: string) => {
  const [hour, minute] = time.split(':');
  return `${hour}:${minute}`;
};

export const isIsraeliIdValid = (id: string) => {
  let strId = String(id).trim();
  if (strId.length !== 9) {
    return false;
  }

  let sum = 0;
  let rawVal: number;

  for (let i = 0; i < strId.length; i++) {
    rawVal = Number(strId[i]) * ((i % 2) + 1);
    sum += rawVal > 9 ? rawVal - 9 : rawVal;
  }
  return sum % 10 === 0;
};

export function isValidEmail(email: string): boolean {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export const isRtl = (text: string): boolean => {
  const rtlChar = /[\u0591-\u07FF\uFB1D-\uFDFD\uFE70-\uFEFC]/;
  return rtlChar.test(text);
};

export function isOnlyLettersOrSpaces(input: string): boolean {
  // chacks that only contains letters in English, Hebrew or Arabic
  const regex = /^[A-Za-z\u0590-\u05FF\u0600-\u06FF\s]+$/;
  return regex.test(input);
}

export function formatTime(date: string | Date, formatStr = 'HH:mm') {
  const dateObj = parseDate(date);
  return format(dateObj, formatStr);
}

export function formatDate(date: Date | string, formatStr = 'dd.MM.yyyy') {
  const dateObj = parseDate(date);
  return format(dateObj, formatStr);
}

export function parseDate(date: string | Date) {
  let dateObj: Date;
  if (typeof date === 'string') {
    if (!date.endsWith('Z')) date += 'Z';
    dateObj = parseISO(date); // Correctly parse the string as UTC
  } else {
    dateObj = date;
  }

  return dateObj;
}

export function getTextWidth(text: string, font: string): number {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (!context) return 0;

  context.font = font;
  return context.measureText(text).width;
}

export function base64ToBlob(base64: string, mimeType: string) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
}

export function isExternalUrl(url: string): boolean {
  // Check if URL starts with 'http://' or 'https://'
  return /^(http:\/\/|https:\/\/)/.test(url);
}

export const utilService = {
  getRelativeDate,
  makeId,
  isPrimitive,
  formatClockTime,
  isIsraeliIdValid,
  isRtl,
  formatTime,
  formatDate,
  getTextWidth,
  isExternalUrl
};
