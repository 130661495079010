import { useEffect } from 'react';
import SuccessIcon from '@/assets/icons/success_icon_green.svg';
import ErrorIcon from '@/assets/icons/warning_icon_red.svg';
import CloseIcon from '@/assets/icons/close_icon.svg';
import { useAppDispatch } from '@/store/hooks';
import { removeToaster } from '@/store/reducers/toaster.reducer';
import { useTranslation } from 'react-i18next';

export interface Props {
  id: string;
  text?: string;
  title?: string;
  type: 'success' | 'error' | 'warning' | 'info';
  autoClose?: boolean;
  closeDelay?: number;
}
const defaultOptions = {
  text: '',
  title: '',
  type: 'success',
  autoClose: true,
  closeDelay: 5000,
};

export const Toaster = (props: Props) => {
  props = { ...defaultOptions, ...props };
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const getIcon = () => {
    switch (props.type) {
      case 'success':
        return SuccessIcon;
      case 'error':
        return ErrorIcon;
      default:
        return SuccessIcon;
    }
  };

  const closeToaster = () => {
    dispatch(removeToaster(props.id));
  };

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (props.autoClose) {
      interval = setTimeout(() => {
        closeToaster();
      }, props.closeDelay ?? 3000);
    }

    return () => {
      clearTimeout(interval);
    };
  }, []);

  return (
    <div className={`toaster-wrapper container ${props.type}`} role="alert">
      <img className="toaster-close-icon modal-close-btn" src={CloseIcon} alt="close icon" onClick={closeToaster} />
      <div className="toaster-icon">
        <img src={getIcon()} alt={`toaster icon ${props.type}`} />
      </div>
      <div className="toaster-content">
        {props.title && <h3 className="toaster-title">{t(props.title)}</h3>}
        {props.text && <p className="toaster-content">{t(props.text)}</p>}
      </div>
    </div>
  );
};
