import { httpService } from '@/modules/common/services/http.service';

async function blockUser(userId: number) {
  return httpService.post(`users/${userId}/block/send/`);
}

async function reportUser(userId: number, reason: number) {
  return httpService.post(`users/${userId}/report/send/`, { data: { reason, id: userId } });
}

async function unBlockUsers(userIds: number[]) {
  return httpService.post(`users/unblock/`, { data: { users: userIds } });
}

async function getBlockedUsers() {
  return httpService.get(`users/blocked/`);
}
async function getUserRoles() {
  return httpService.get(`users/roles/`);
}

async function getReportUserReasons() {
  return httpService.get(`users/report/reasons/`);
}

export const userService = { blockUser, reportUser, unBlockUsers, getBlockedUsers, getUserRoles, getReportUserReasons };
