import { useAppSelector } from '@/store/hooks';
import { selectUser } from '@/store/reducers/user.reducer';
import { selectOrganization } from '@/store/reducers/organization.reducer';
import { intercomService } from '@/modules/common/services/intercom';

let isIntercomInitialized = false;

export const useIntercom = () => {
  const user = useAppSelector(selectUser);
  const org = useAppSelector(selectOrganization);

  const showIntercom = () => {
    if (!isIntercomInitialized) {
      intercomService.initializeIntercom();
      isIntercomInitialized = true;
    }
    intercomService.showIntercom(user, org);
  };

  return { showIntercom };
};
