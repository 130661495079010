import { Navigate, RouteProps } from 'react-router-dom';

interface GuardRouteOptions {
  component: React.ComponentType<any>;
  isAuth: boolean;
  props?: RouteProps;
}

export const RouteGuard = ({ component: Component, isAuth, ...props }: GuardRouteOptions) => {
  return isAuth ? <Component {...props} /> : <Navigate to="/welcome" />;
};
