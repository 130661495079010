import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState, AppDispatch } from '../store';
import { reportService, CreateReportProps } from '@/modules/report/services/report.service';
import type { Report } from '@/types/';
import { setFinishLoading, setLoading } from './status.reducer';
import { ReportApproveData } from '@/types/Report';
import { showError } from './error.reducer';
import { addToaster } from './toaster.reducer';
import { sentryService } from '@/modules/common/services/sentry.service';


const REPORT_TIME_ERROR_CODE = '403AttendanceReportTimeError';
const REPORT_DATE_ERROR_CODE = '400AttendanceReportDateError';

export interface ReportState {
  reports: Report[];
  reportsNextUrl?: string | null;
  reportsCount: number;
}

// Define the initial state using that type
export const initialState: ReportState = {
  reports: [],
  reportsNextUrl: null,
  reportsCount: 0,
};

export const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {
    setReports: (state, action: PayloadAction<Report[]>) => {
      state.reports = action.payload;
    },
    setReportsNextUrl: (state, action: PayloadAction<string | null>) => {
      state.reportsNextUrl = action.payload;
    },
    setReportsCount: (state, action: PayloadAction<number>) => {
      state.reportsCount = action.payload;
    },
  },
});
export const { setReports, setReportsNextUrl, setReportsCount } = reportSlice.actions;

const clearReportData = () => async (dispatch: AppDispatch) => {
  dispatch(setReports([]));
  dispatch(setReportsNextUrl(null));
  dispatch(setReportsCount(0));
};

// THUNKS
export const getReports =
  (userId: number, orgId: number, nextUrl?: string, shouldReset: boolean = false) =>
  async (dispatch: AppDispatch, getState: Function) => {
    const { report, status } = getState();
    if (!shouldReset && report.reports && report.reports.length > 0 && !nextUrl) return;

    const loadingId = 'getReports';
    if (status.ids.includes(loadingId)) return;

    if (shouldReset) {
      await dispatch(clearReportData());
    }

    try {
      dispatch(setLoading(loadingId));

      const res = await reportService.getReports(userId, orgId, nextUrl);
      if (!res) throw new Error('No reports found');

      const { results, next, count } = res;
      dispatch(setReports([...getState().report.reports, ...results]));

      if (next && next === report.reportsNextUrl) dispatch(setReportsNextUrl(null));
      else dispatch(setReportsNextUrl(next));

      dispatch(setReportsCount(count));

      dispatch(setFinishLoading(loadingId));
    } catch (err) {
      dispatch(setFinishLoading(loadingId));
      dispatch(
        addToaster({
          type: 'error',
          title: 'error_title',
          text: 'oops_smthng_went_wrong',
        })
      );
      console.log('Error while getting reports:', err);
      sentryService.captureException(err);
    }
  };

export const deleteReport = (reportId: number) => async (dispatch: AppDispatch, getState: Function) => {
  const { report } = getState();
  const loadingId = 'deleteReport';

  try {
    dispatch(setLoading(loadingId));
    await reportService.deleteReport(reportId);
    dispatch(setReports([...report.reports.filter((report: Report) => report.id !== reportId)]));
    dispatch(setReportsCount(report.reportsCount - 1));

    dispatch(setFinishLoading(loadingId));
  } catch (err) {
    dispatch(setFinishLoading(loadingId));
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log(`Error while deleting report ${reportId}:`, err);
    sentryService.captureException(err);
  }
};

export const saveReport = (payload: CreateReportProps) => async (dispatch: AppDispatch, getState: Function) => {
  const { report } = getState();
  const loadingId = 'saveReport';
  const isEdit = !!payload.reportId;

  try {
    dispatch(setLoading(loadingId));
    const res = await reportService.saveReport(payload);

    if (isEdit) {
      const idx = report.reports.findIndex((report: Report) => report.id === payload.reportId);
      if (idx != -1) {
        const temp = [...report.reports];
        temp.splice(idx, 1, res);
        dispatch(setReports([...temp]));
      }
    } else {
      dispatch(setReports([res, ...report.reports]));
      dispatch(setReportsCount(report.reportsCount + 1));

      if (report.reportsNextUrl) {
        // Updating the nextURL offset because a new report was added
        const updatedUrl = getUpdatedOffsetUrl(report.reportsNextUrl);
        if (updatedUrl) dispatch(setReportsNextUrl(updatedUrl));
      }
    }

    dispatch(setFinishLoading(loadingId));
    return res;
  } catch (err: any) {
    dispatch(setFinishLoading(loadingId));

    if ([REPORT_TIME_ERROR_CODE, REPORT_DATE_ERROR_CODE].includes(err?.code)) {
      dispatch(
        showError({
          title: 'Error',
          text: err.message,
        })
      );
    } else {
      dispatch(
        addToaster({
          type: 'error',
          title: 'error_title',
          text: 'oops_smthng_went_wrong',
        })
      );
    }
    sentryService.captureException(err);
  }
};

export const approveReports = (payload: ReportApproveData) => async (dispatch: AppDispatch, getState: Function) => {
  const { user, organization } = getState();
  const loadingId = 'saveReport';

  try {
    dispatch(setLoading(loadingId));
    const res = await reportService.approveReport(payload);

    dispatch(clearReportData());
    dispatch(getReports(user.user.pk, organization.organization.id));

    dispatch(setFinishLoading(loadingId));
    return res;
  } catch (err) {
    dispatch(setFinishLoading(loadingId));
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('Error while approving reports:', err);
    sentryService.captureException(err);
  }
};

export const selectReports = (state: RootState) => state.report.reports;
export const selectReportsCount = (state: RootState) => state.report.reportsCount;
export const selectReportsNextUrl = (state: RootState) => state.report.reportsNextUrl;

export default reportSlice.reducer;

function getUpdatedOffsetUrl(urlStr: string) {
  if (!urlStr) return;

  var url = new URL(urlStr);
  if (url.searchParams.has('offset')) {
    url.searchParams.set('offset', (Number(url.searchParams.get('offset')) + 1).toString());
  }
  return url.toString();
}
