import { Capacitor } from '@capacitor/core';
import { useEffect } from 'react';
import { NOTIFICATION_CHANNEL, pushNotificationService } from '../services/push-notification.service';
import { deviceService } from '../services/device.service';

export const NotificationController = () => {
  useEffect(() => {
    const setupNotifications = async () => {
      if (!Capacitor.isNativePlatform()) return;

      // register to notifications
      // create default channel
      // register to onMessageReceived
      await pushNotificationService.init();
      deviceService.updateDeviceInfo();
    };
    setupNotifications();

    return () => {
      pushNotificationService.deleteChannel(NOTIFICATION_CHANNEL.id);
    };
  }, []);

  return null;
};
