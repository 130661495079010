import { httpService } from '@/modules/common/services/http.service';
import { parseDate, utilService } from '@/modules/common/services/util.service';
import { SocketMessage } from '@/modules/common/services/ws.service';
import { Message } from '@/types/Chat';
import { isSameDay, isToday } from 'date-fns';

export const CHAT_ROOMS = {
  ORGANIZATION: 0,
  OPPORTUNITY: 1,
  GROUP: 2,
  PERSONAL: 3,
  TRIBU: 4,
};

async function getChatRooms(nextUrl?: string) {
  try {
    const res = await httpService.get('chats', { fullUrl: nextUrl ? nextUrl : '' });
    return res;
  } catch (error) {
    console.log(error);
  }
}

async function getChatById(roomId: number) {
  try {
    const res = await httpService.get(`chats/${roomId}/`);
    return res;
  } catch (error) {
    console.log(error);
  }
}

async function getChatMessageHistory(roomId: number, nextUrl?: string) {
  try {
    const res = await httpService.get(`chats/${roomId}/history/`, { params: { limit: 100 }, fullUrl: nextUrl ? nextUrl : '' });
    return res;
  } catch (error) {
    console.log(error);
  }
}

async function sendChatMessage(roomId: number, msgData: any) {
  try {
    const data = {
      id: roomId,
      data: msgData,
    };
    const res = await httpService.post(`chats/${roomId}/message/`, data);
    return res;
  } catch (error) {
    console.log(error);
  }
}

async function markAsRead(roomId: number) {
  try {
    const res = await httpService.post(`chats/${roomId}/mark_as_read/`);
    return res;
  } catch (error) {
    console.log(error);
  }
}

function getRoomTypeName(type: number) {
  switch (type) {
    case CHAT_ROOMS.GROUP:
      return 'groups';
    case CHAT_ROOMS.ORGANIZATION:
      return 'organizations';
    case CHAT_ROOMS.OPPORTUNITY:
      return 'opportunities';
    case CHAT_ROOMS.PERSONAL:
      return 'personal';
  }
}

function formatMessageDateOrTime(date?: string | Date | null) {
  if (!date) return '';
  const dateObj = parseDate(date);

  if (isToday(dateObj)) {
    return utilService.formatTime(dateObj);
  } else {
    return utilService.formatDate(dateObj);
  }
}

function isFirstDayMessage(date: string | Date | null, prevDate: string | Date | null) {
  if (!date) return false;
  if (!prevDate) return true;
  date = new Date(date);
  prevDate = new Date(prevDate);

  return !isSameDay(prevDate, date);
}

function extractChatMessageFromSocketMessage(socketMessage: SocketMessage): Message {
  return {
    id: socketMessage.id!,
    text: socketMessage.text || '',
    date_created: socketMessage.date_created || '',
    user: socketMessage.user || undefined,
    room: socketMessage.room || undefined,
    room_id: socketMessage.room_id || undefined,
    is_admin: socketMessage.is_admin || false,
    attachments: socketMessage.attachments || [],
    is_municipality: socketMessage.is_municipality || false,
    organization_id: socketMessage.organization_id || undefined,
  };
}

export const chatService = {
  getChatRooms,
  getChatById,
  getRoomTypeName,
  getChatMessageHistory,
  sendChatMessage,
  markAsRead,
  formatMessageDateOrTime,
  isFirstDayMessage,
  extractChatMessageFromSocketMessage,
};
