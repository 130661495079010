import { User } from "@/types/User";
import * as Sentry from "@sentry/react";



export const sentryService = {
    captureException,
    setUser
}

function captureException(err: any){
    Sentry.captureException(err);
}

function setUser(user: User | null) {
    if (!user) {
        Sentry.setUser({});
        return;
    }
    const sentryUser = {
        id: user.pk.toString(),
        israeliId: user.israel_id,
        name: `${user.first_name} ${user.last_name}`,
    }

    Sentry.setUser(sentryUser);
}