import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { createToasterMessage } from '@/modules/common/services/toaster.service.ts';
import type { RootState } from '../store';
import type { Toaster } from '@/types/Toaster';

// Define a type for the slice state
export interface ToasterState {
  toasters: Toaster[];
}

// Define the initial state using that type
export const initialState: ToasterState = {
  toasters: [],
};

export const toasterSlice = createSlice({
  name: 'toaster',
  initialState,
  reducers: {
    addToaster: (state, action: PayloadAction<Toaster>) => {
      const toasterMessage = createToasterMessage(action.payload);
      const isToasterSignatureExists = state.toasters.some((toaster: Toaster) => toaster.signature && toaster.signature === toasterMessage.signature);
      if (isToasterSignatureExists) return;
      state.toasters = [toasterMessage, ...state.toasters];
    },
    removeToaster: (state, action: PayloadAction<string>) => {
      state.toasters = state.toasters.filter((toaster: any) => toaster.id !== action.payload);
    },
  },
});

export const { addToaster, removeToaster } = toasterSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectTostars = (state: RootState) => state.toaster.toasters;

export default toasterSlice.reducer;
