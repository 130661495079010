import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';

interface ErrorPreview {
  title: string;
  text?: string | string[];
}

// Define a type for the slice state
export interface ErrorState {
  error: ErrorPreview | null;
}

// Define the initial state using that type
export const initialState: ErrorState = {
  error: null,
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    showError: (state, action: PayloadAction<ErrorPreview>) => {
      state.error = action.payload;
    },
    clearError: (state) => {
      state.error = null;
    },
  },
});

export default errorSlice.reducer;

export const { showError, clearError } = errorSlice.actions;

export const selectError = (state: RootState) => state.error.error;
