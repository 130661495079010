import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '@/locale/en.json';
import he from '@/locale/he.json';
import ar from '@/locale/ar.json';

export default i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      he: {
        translation: he,
      },
      ar: {
        translation: ar,
      },
    },
    lng: 'he', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });
