import { authService } from '@/modules/auth/services/auth.service';

export interface Error {
  type: string;
  message: string;
  statusCode?: number;
}

export const ERROR_TYPES = {
  NETWORK: 'NETWORK',
  VALIDATION: 'VALIDATION',
  CONFLICT: 'CONFLICT',
  AUTH: 'AUTH',
};

function errorHandler(err: any) {
  let errorType = ERROR_TYPES.NETWORK;
  const statusCode = err.response?.status || err.status_code || err.response?.data.status_code;
  const message = err.response?.data?.errors?.message || err.response?.data?.error || err.message || err.error?.[0];
  const code = err.response?.data?.code || err.code;

  if (statusCode === 409) {
    errorType = ERROR_TYPES.CONFLICT;
  } else if (statusCode === 400) {
    errorType = ERROR_TYPES.VALIDATION;
  } else if (statusCode === 401) {
    errorType = ERROR_TYPES.AUTH;

    // the session user is not authorized
    authService.clearData();
  } else if (statusCode === 403) {
    errorType = ERROR_TYPES.AUTH;
  } else if (statusCode === 500) {
    errorType = ERROR_TYPES.NETWORK;
  }

  // TODO - send error to sentry or backend

  return Promise.reject({
    type: errorType,
    message,
    code,
    statusCode,
  });
}

export const errorService = {
  errorHandler,
};
