import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../store';

// Define a type for the slice state
export interface ModalState {
  openModals: number;
}

// Define the initial state using that type
export const initialState: ModalState = {
  openModals: 0,
};

// reducer functions
export const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    modalOpened: (state) => {
      state.openModals += 1;
    },
    modalClosed: (state) => {
      if (state.openModals > 0) {
        state.openModals -= 1;
      }
    },
  },
});

export const { modalOpened, modalClosed } = modalSlice.actions;

export default modalSlice.reducer;

export const selectModalState = (state: RootState) => state.modal.openModals > 0;
