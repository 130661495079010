import { useEffect } from 'react';
import { Capacitor } from '@capacitor/core';
import { Network } from '@capacitor/network';
import { useAppDispatch } from '@/store/hooks';
import { addToaster } from '@/store/reducers/toaster.reducer';

export const NetworkStatus = () => {
  const dispatch = useAppDispatch();

  const showConnectionError = () => {
    dispatch(addToaster({ type: 'error', text: 'network_error_default' }));
  };

  useEffect(() => {
    const handleWebNetworkChange = () => {
      if (!window.navigator.onLine) {
        showConnectionError();
      }
    };

    const handleMobileNetworkChange = (status: { connected: boolean }) => {
      if (!status.connected) {
        showConnectionError();
      }
    };

    // Run on first load
    if (Capacitor.isNativePlatform()) {
      Network.getStatus().then((status) => {
        handleMobileNetworkChange(status);
      });
    } else {
      handleWebNetworkChange();
    }

    window.addEventListener('online', handleWebNetworkChange);
    window.addEventListener('offline', handleWebNetworkChange);

    // For Capacitor
    if (Capacitor.isNativePlatform()) {
      Network.addListener('networkStatusChange', handleMobileNetworkChange);
    }

    return () => {
      window.removeEventListener('online', handleWebNetworkChange);
      window.removeEventListener('offline', handleWebNetworkChange);

      // For Capacitor
      if (Capacitor.isNativePlatform()) {
        Network.removeAllListeners();
      }
    };
  }, []);
  return null;
};
