import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppDispatch, RootState } from '../store';
import { setFinishLoading, setLoading } from './status.reducer';
import { addToaster } from './toaster.reducer';
import { languageService } from '@/modules/common/services/language.service';

export interface LanguageState {
  lang: string;
}

export const initialState: LanguageState = {
  lang: 'he',
};

export const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLang: (state, action: PayloadAction<string>) => {
      state.lang = action.payload;
    },
  },
});

export default languageSlice.reducer;
export const { setLang } = languageSlice.actions;

export const getLanguage = () => async (dispatch: AppDispatch) => {
  try {
    const lang = await languageService.getCurrentLanguage();

    dispatch(setLang(lang));
    return lang;
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('While getting language:', err);
  }
};
export const setLanguage = (lang: string) => async (dispatch: AppDispatch, getState: Function) => {
  const { status } = getState();
  const loadingId = 'setLanguage';

  if (status.ids.includes(loadingId)) return;

  try {
    dispatch(setLoading(loadingId));
    languageService.setCurrentLanguage(lang);
    dispatch(setLang(lang));

    return lang;
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('While setting language:', err);
  } finally {
    dispatch(setFinishLoading(loadingId));
  }
};

export const selectCurrentLanguage = (state: RootState) => state.language.lang;
