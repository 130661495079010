import { useEffect, useState } from 'react';

export const useMobile = (maxSize = 800): boolean => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < maxSize);

  useEffect(() => {
    function handleResize() {
      setIsMobile(window.innerWidth < maxSize);
    }

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile;
};
