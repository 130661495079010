console.log('MODE:', import.meta.env.MODE);

export const config = {
  dmsBaseURL: import.meta.env.VITE_DMS_BASE_URL,
  baseUrl: import.meta.env.VITE_BASE_URL,
  IDMRedirectUrl: import.meta.env.VITE_IDM_REDIRECT_URL,
  IDMBaseUrl: import.meta.env.VITE_IDM_BASE_URL,
  IDMState: import.meta.env.VITE_IDM_STATE,
  IDMLogoutUrl: import.meta.env.VITE_LOGOUT_URL,
  chetWebsocketUrl: import.meta.env.VITE_CHAT_WEBSOCKET_URL,
  IDMClientId: import.meta.env.VITE_IDM_CLIENT_ID,
  IDMScope: import.meta.env.VITE_IDM_SCOPE,
  JwtSecret: import.meta.env.VITE_JWT_SECRET,
  notificationChannel: import.meta.env.VITE_NOTIFICATION_CHANNEL,
  appcenterId: import.meta.env.VITE_APPCENTER_ID,
  userToken: import.meta.env.VITE_USER_TOKEN,
  isNeededToUsePredefinedToken: import.meta.env.VITE_IS_NEEDED_TO_USE_PREDEFINED_TOKEN,
  googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
  isFrontendLocal: JSON.parse(import.meta.env.VITE_IS_FRONTEND_LOCAL || 'false'),
  mode: import.meta.env.MODE,
  isDev: import.meta.env.MODE === 'dev',
  intercomAppid: import.meta.env.VITE_INTERCOM_APP_ID,
  intercomIOSApiKey: import.meta.env.VITE_INTERCOM_IOS_API_KEY,
  intercomAndroidApiKey: import.meta.env.VITE_INTERCOM_ANDROID_API_KEY,
  mediaBucketName: import.meta.env.VITE_MEDIA_BUCKET_NAME,
  imageResizeCloudfrontUrl: import.meta.env.VITE_IMAGE_RESIZE_CLOUDFRONT_URL,
  imageOriginalCloudfrontUrl: import.meta.env.VITE_IMAGE_ORIGINAL_CLOUDFRONT_URL,
  sentryDns: import.meta.env.VITE_SENTRY_DNS
};
