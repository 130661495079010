import { Message } from '@/types/Chat';
export type MessageHandler = (data: SocketMessage) => void;

export interface WebSocketClientConfig {
  reconnectAttempts?: number;
  reconnectDelay?: number;
  onConnectCb?: () => void;
}

export type MessageAction = 'msg' | 'delete_msg' | 'history' | 'mark_as_read' | 'unread_counters' | 'all';

export interface SocketMessage extends Partial<Message> {
  action?: MessageAction;
  room_id?: number;
  msg_id?: number;
  text?: string;
  id__lt?: number;
  limit?: number;
  unread?: number;
}

export interface WebSocketClient {
  connect: (onMessage?: MessageHandler) => void;
  disconnect: () => void;
  sendMessage: (messageObj: SocketMessage) => void;
  subscribe: (action: MessageAction, callback: MessageHandler) => (() => void);
  unsubscribe: (action: MessageAction, callback: MessageHandler) => void;
}

type Subscriptions = {
  [K in MessageAction]?: MessageHandler[];
};

export const createWebSocketClient = (url: string, config?: WebSocketClientConfig): WebSocketClient => {
  let socket: WebSocket | null = null;
  let reconnectAttempts = 0;
  const maxReconnectAttempts = config?.reconnectAttempts ?? 3;
  const reconnectDelay = config?.reconnectDelay ?? 3000;

  // Then, in your WebSocketClient implementation:
  const subscriptions: Subscriptions = {};

  const connect = (onMessage?: MessageHandler) => {
    if (!socket) {
      socket = new WebSocket(url);
    }

    socket.onopen = () => {
      console.log('WebSocket Client Connected');
      reconnectAttempts = 0; // Reset reconnect attempts on successful connection
      if (config?.onConnectCb) {
        config.onConnectCb();
      }
    };

    socket.onmessage = (event) => {
      const message: SocketMessage = JSON.parse(event.data);
      if (onMessage) {
        // allows to pass specific message handler
        onMessage(message);
      }

      const action = message.action ? message.action : 'all';

      if (action && typeof action === 'string') {
        const handlers = subscriptions[action] || [];
        handlers?.forEach((handler: MessageHandler) => handler(message));
      }
    };

    socket.onclose = (event) => {
      console.log('WebSocket Closed:', event);
      socket = null;
      // Attempt to reconnect with a delay if the close event was not intentional
      if (!event.wasClean && reconnectAttempts < maxReconnectAttempts) {
        setTimeout(() => {
          console.log('Attempting to reconnect...');
          reconnectAttempts++;
          connect(onMessage); // Attempt to reconnect
        }, reconnectDelay);
      }
    };

    socket.onerror = (error) => {
      console.error('WebSocket Error:', error);
      // Optionally, close the socket on error to trigger the onclose handler for reconnection
      socket?.close();
    };
  };

  const subscribe = (action: MessageAction, callback: MessageHandler): (() => void) => {
    if (!subscriptions[action]) {
      subscriptions[action] = [];
    }
    subscriptions[action]!.push(callback);

    return () => {
      unsubscribe(action, callback);
    };
  };

  const unsubscribe = (action: MessageAction, callback: MessageHandler) => {
    if (subscriptions[action] == null) return;
    subscriptions[action] = subscriptions[action]!.filter((handler) => handler !== callback);
  };

  const disconnect = () => {
    if (socket) {
      socket.close(1000, 'Client disconnect');
    }
  };

  const sendMessage = (messageObj: SocketMessage) => {
    if (!socket) throw new Error('Cannot send message. Socket is not connected.');
    socket.send(JSON.stringify(messageObj));
  };

  return { connect, disconnect, sendMessage, subscribe, unsubscribe };
};
