import { pushNotificationService } from './push-notification.service';
import { storageService } from './storage';
import { languageService } from './language.service';
import { Capacitor } from '@capacitor/core';
import { httpService } from './http.service';
// dynamiclly import Device from @capacitor/device only if we are running on native platform

const DEVICE_ID_KEY = 'device_id';

async function deviceRequest(obj: any, devId: string | null) {
  let url = `devices/`;
  if (devId != null) {
    url += devId + '/';
  }

  const options = {
    method: devId === null ? 'POST' : 'PATCH',
    data: obj,
  };
  return httpService.ajax(url, options);
}

async function updateDeviceInfo() {
  const { Device } = Capacitor.isNativePlatform() ? await import('@capacitor/device') : { Device: null };
  try {
    console.log('-- attempting to update device info --');

    const deviceObject = {
      dev_type: Capacitor.getPlatform().toUpperCase(),
      reg_id: await pushNotificationService.getToken(),
      dev_id: await Device?.getId(),
      language: await languageService.getCurrentLanguage(),
    };

    let deviceId = await getStoredDeviceId();
    console.log('------ UPDATING DEVICE INFO ------');

    console.log('deviceObject:', JSON.stringify(deviceObject));
    const deviceInfo = await deviceRequest(deviceObject, deviceId);
    if (deviceInfo?.id) {
      setStoredDeviceId(deviceInfo.id);
    }
  } catch (err: any) {
    console.log('Error while updating device info error: ', err);
  }
}

async function removeRegisteredDevice() {
  let deviceId = await getStoredDeviceId();
  if (deviceId) {
    await httpService.delete('devices/' + deviceId + '/');
    removeStoredDeviceId();
  }
}

async function getStoredDeviceId(): Promise<string | null> {
  return (await storageService.load(DEVICE_ID_KEY)) ?? null;
}

function setStoredDeviceId(deviceId: string) {
  storageService.save(DEVICE_ID_KEY, deviceId);
}

function removeStoredDeviceId() {
  storageService.remove(DEVICE_ID_KEY);
}

export const deviceService = {
  updateDeviceInfo,
  removeStoredDeviceId,
  removeRegisteredDevice,
};
