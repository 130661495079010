/**
 * Intercom Service Module
 * -----------------------
 * 
 * This file dynamically exports the appropriate intercom service (web or mobile)
 * based on the current platform, using Capacitor's isNativePlatform() to determine
 * the environment.
 * 
 * IMPORTANT:
 * Each function defined in either of the sub-service modules (intercom-web.ts or 
 * intercom-mobile.ts) must have a counterpart in the other. This is to ensure 
 * consistent API and functionality across both platforms. If you add a new 
 * function to one service, make sure to add a corresponding function (even if it's 
 * a no-op) to the other service.
 */

import { Capacitor } from '@capacitor/core';
import intercomWeb from './intercom-web';
import intercomMobile from './intercom-mobile';

export const intercomService = Capacitor.isNativePlatform() ? intercomMobile : intercomWeb;
