import EventEmitter from 'eventemitter3';

class EventBus extends EventEmitter {}

// Export a singleton instance
export const eventBus = new EventBus();

export const ACTIONS = {
  REPORT_WAS_APPROVED: 'reportWasApproved',
  REPORT_WAS_REJECTED: 'reportWasRejected',
  JOINED_TO_VO: 'joinedToVO',
  ADDED_TO_VO_BY_ADMIN: 'addedToVOByAdmin',
  REJECTED_FROM_VO: 'rejectedFromVO',
  REMOVED_FROM_VO: 'removedFromVO',
  REJECTED_FROM_VO_WITH_REASON: 'rejectedFromVOWithReason',
  REJECTED_REPORT_WITH_REASON: 'reportRejectedWithReason',
  REMOVED_FROM_VO_WITH_REASON: 'removedFromVOWithReason',
  USER_LEFT_OPPORTUNITY: 'userLeftOpportunnity',
  USER_CANCEL_OPPORTUNITY_REQUEST: 'userCancelOpportunityRequest',

  APP_IS_ACTIVE: 'appIsActive',
};
