import { ACTIONS, eventBus } from './event-bus.service';

export interface AdaptedNotification {
  id: string;
  type: NotificationType;
  date: string;
  details: any;
}

export enum NotificationType {
  joinedToOrg = 0,
  addedToOrgByAdmin = 1,
  rejectedFromOrg = 2,
  removedFromOrg = 3,
  joinedToGroup = 4,
  addedToGroup = 5,
  rejectedFromGroup = 6,
  removedFromGroup = 7,

  // --- relevant part ---
  joinedToVO = 8,
  addedToVOByAdmin = 9,
  rejectedFromVO = 10,
  removedFromVO = 11,

  reportWasApproved = 12,
  reportWasRejected = 13,
  // ---------------------

  rejectedFromOrgWithReason = 14,
  rejectedFromGroupWithReason = 15,

  // --- relevant part ---
  rejectedFromVOWithReason = 16,
  reportRejectedWithReason = 17,
  // ---------------------

  surveyCreated = 18,
  obtainedLimitedOrgAdmin = 19,
  obtainedLimitedGroupAdmin = 20,
  allowMedia = 21,
  disallowMedia = 22,
  didntCreateReportFor30days = 23,
  didntJoinVOfor30days = 24,
  goal = 25,
  removedFromOrgWithReason = 26,

  // --- relevant part ---
  removedFromVOWithReason = 27,
  // ---------------------

  removedFromGroupWithReason = 28,
  needCreated = 29,
  voCreated = 30,
  postDeleted = 31,
  userLeftOrganization = 32,
  userCancelOrganizationRequest = 33,

  // --- relevant part ---
  userLeftOpportunnity = 34,
  userCancelOpportunityRequest = 35,
  // ---------------------
}

export async function handleNotificationAction(notification: AdaptedNotification) {
  try {
    const type = typeof notification.type === 'number' ? notification.type : parseInt(notification.type);

    if (!type) {
      throw new Error('Notification type is not defined');
    }

    console.log('handleNotificationAction ~ notification type:', type);
    switch (type) {
      case NotificationType.joinedToVO:
        eventBus.emit(ACTIONS.JOINED_TO_VO, notification);
        break;
      case NotificationType.addedToVOByAdmin:
        eventBus.emit(ACTIONS.ADDED_TO_VO_BY_ADMIN, notification);
        break;
      case NotificationType.rejectedFromVO:
        eventBus.emit(ACTIONS.REJECTED_FROM_VO, notification);
        break;
      case NotificationType.rejectedFromVOWithReason:
        eventBus.emit(ACTIONS.REJECTED_FROM_VO_WITH_REASON, notification);
        break;
      case NotificationType.removedFromVO:
        eventBus.emit(ACTIONS.REMOVED_FROM_VO, notification);
        break;
      case NotificationType.removedFromVOWithReason:
        eventBus.emit(ACTIONS.REMOVED_FROM_VO_WITH_REASON, notification);
        break;
      case NotificationType.userLeftOpportunnity:
        eventBus.emit(ACTIONS.USER_LEFT_OPPORTUNITY, notification);
        break;
      case NotificationType.userCancelOpportunityRequest:
        eventBus.emit(ACTIONS.USER_CANCEL_OPPORTUNITY_REQUEST, notification);
        break;
      case NotificationType.reportWasApproved:
        eventBus.emit(ACTIONS.REPORT_WAS_APPROVED, notification);
        break;
      case NotificationType.reportWasRejected:
        eventBus.emit(ACTIONS.REPORT_WAS_REJECTED, notification);
        break;
      case NotificationType.reportRejectedWithReason:
        eventBus.emit(ACTIONS.REJECTED_REPORT_WITH_REASON, notification);
        break;
    }
  } catch (err: any) {
    console.log('Error while handling notification action:', err.notification);
  }
}

export function notificationAdaptor(notification: any): AdaptedNotification | null {
  let data = notification.data || notification.message;

  if (typeof data === 'string') {
    data = JSON.parse(data);
  }

  const { type, id, date, ...rest } = data;
  if (!type) {
    return null;
  }

  let messageDetails = rest || {};

  if (typeof messageDetails === 'string') {
    messageDetails = JSON.parse(messageDetails);
  }

  return {
    id,
    type,
    date,
    details: messageDetails,
  };
}

export function createFakeNotification(type: NotificationType, oppId: number): AdaptedNotification {
  return {
    id: 'fake_id',
    type,
    date: new Date().toISOString(),
    details: {
      opportunity: {
        id: oppId,
      },
    },
  };
}
