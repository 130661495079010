import { makeId } from './util.service';
import type { Toaster } from '@/types/Toaster';

export function createToasterMessage(payload: Toaster): Toaster {
  const id = makeId();
  const signature = `${payload.type}_${payload.title}_${payload.text}`

  if (!payload.id) payload.id = id;
  if (!payload.signature) payload.signature = signature;

  return payload;
}
