import CloseIcon from '@/assets/icons/close_icon.svg';
import { ModalWrapper } from './ModalWrapper';

interface Props {
  title: string;
  text?: string;
  buttons: React.ReactNode;
  onClose: () => void;
  topContent?: React.ReactNode;
  className?: string;
  buttonsClassName?: string;
  hideCloseBtn?: boolean;
}

export const ConfirmModal = (props: Props) => {
  return (
    <ModalWrapper>
      <section className={`confirm-modal-container modal-container ${props.className ?? ''}`}>
        {props.hideCloseBtn ? null : <img className="confirm-close-icon modal-close-btn" src={CloseIcon} alt="close icon" onClick={props.onClose} />}
        <div className="text-content">
          {props.topContent ? props.topContent : null}

          <h3>{props.title}</h3>
          {props.text && <p>{props.text}</p>}
        </div>
        <div className={`buttons-wrapper ${props.buttonsClassName ?? ''}`}>{props.buttons}</div>
      </section>
    </ModalWrapper>
  );
};
