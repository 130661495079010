import { Toaster } from './Toaster';
import { useAppSelector } from '@/store/hooks';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

export const ToasterList = () => {
  const { toasters } = useAppSelector((state: any) => state.toaster);

  return (
    <TransitionGroup className="toaster-list-container">
      {toasters &&
        toasters.length > 0 &&
        toasters.map((toaster: any) => {
          return (
            <CSSTransition timeout={800} classNames="slide" key={toaster.id}>
              {() => <Toaster {...toaster} key={toaster.id} />}
            </CSSTransition>
          );
        })}
    </TransitionGroup>
  );
};
