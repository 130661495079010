import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState, AppDispatch } from '../store';
import type { Opportunity } from '@/types/';
import { LOADING, SUCCEEDED, FAILED, IDLE } from '../constants';
import { setFinishLoading, setLoading, Statues } from './status.reducer';
import { opportunityService } from '@/modules/opportunity/services/opportunity.service';
import { addToaster } from './toaster.reducer';
import { sentryService } from '@/modules/common/services/sentry.service';


export interface OpportunityState {
  opp: Opportunity | null;
  joinedAndPendingOpps: Opportunity[];
  recommendedOpps: Opportunity[];
  catagoryOpps: Opportunity[];
  approvedOpps: Opportunity[];

  nextJoinedAndPendingOppsUrl?: string | null;
  nextRecommendedOppsUrl?: string | null;
  nextCatagoryOppsUrl?: string | null;
  nextApprovedOppsUrl?: string | null;

  joinedAndPendingOppsCount: number;
  catagoryOppsCount: number;

  localStatus: Statues;
}

// Define the initial state using that type
export const initialState: OpportunityState = {
  opp: null,
  joinedAndPendingOpps: [],
  recommendedOpps: [],
  catagoryOpps: [],
  approvedOpps: [],

  nextJoinedAndPendingOppsUrl: null,
  nextRecommendedOppsUrl: null,
  nextCatagoryOppsUrl: null,
  nextApprovedOppsUrl: null,

  joinedAndPendingOppsCount: 0,
  catagoryOppsCount: 0,

  localStatus: IDLE,
};

export const opportunitySlice = createSlice({
  name: 'opportunity',
  initialState,
  reducers: {
    // recommended opportunities
    setRecommendedOpps: (state, action: PayloadAction<Opportunity[]>) => {
      state.recommendedOpps = action.payload;
    },
    setNextRecommendedOppsUrl: (state, action: PayloadAction<string | null>) => {
      state.nextRecommendedOppsUrl = action.payload;
    },

    // approved opportunities
    setApprovedOpps: (state, action: PayloadAction<Opportunity[]>) => {
      state.approvedOpps = action.payload;
    },
    setNextApprovedOppsUrl: (state, action: PayloadAction<string | null>) => {
      state.nextApprovedOppsUrl = action.payload;
    },

    // catagory opportunities
    setCatagoryOpps: (state, action: PayloadAction<Opportunity[]>) => {
      state.catagoryOpps = action.payload;
    },
    setCatagoryOppsNextUrl: (state, action: PayloadAction<string | null>) => {
      state.nextCatagoryOppsUrl = action.payload;
    },
    setCatagoryOppsCount: (state, action: PayloadAction<number>) => {
      state.catagoryOppsCount = action.payload;
    },
    resetCatagoryOpps: (state) => {
      state.catagoryOpps = [];
      state.nextCatagoryOppsUrl = null;
      state.catagoryOppsCount = 0;
    },

    // joined and pending opportunities
    setJoinedAndPendingOpps: (state, action: PayloadAction<Opportunity[]>) => {
      state.joinedAndPendingOpps = action.payload;
    },
    setJoinedAndPendingOppsNextUrl: (state, action: PayloadAction<string | null>) => {
      state.nextJoinedAndPendingOppsUrl = action.payload;
    },
    setJoinedAndPendingOppsCount: (state, action: PayloadAction<number>) => {
      state.joinedAndPendingOppsCount = action.payload;
    },

    // opporunity
    setOpp: (state, action: PayloadAction<Opportunity | null>) => {
      state.opp = action.payload;
    },

    setLocalStatus: (state, action: PayloadAction<Statues>) => {
      state.localStatus = action.payload;
    },
  },
});
export const {
  setRecommendedOpps,
  setNextRecommendedOppsUrl,
  setApprovedOpps,
  setNextApprovedOppsUrl,
  setCatagoryOpps,
  setCatagoryOppsNextUrl,
  setCatagoryOppsCount,
  resetCatagoryOpps,
  setJoinedAndPendingOpps,
  setJoinedAndPendingOppsNextUrl,
  setJoinedAndPendingOppsCount,
  setOpp,
  setLocalStatus,
} = opportunitySlice.actions;

const resetRecommendedOpps = () => async (dispatch: AppDispatch) => {
  dispatch(setRecommendedOpps([]));
  dispatch(setNextRecommendedOppsUrl(null));
};
const resetJoinedAndPendingOpps = () => async (dispatch: AppDispatch) => {
  dispatch(setJoinedAndPendingOpps([]));
  dispatch(setJoinedAndPendingOppsNextUrl(null));
  dispatch(setJoinedAndPendingOppsCount(0));
};

const resetApprovedOpps = () => async (dispatch: AppDispatch) => {
  dispatch(setApprovedOpps([]));
  dispatch(setNextApprovedOppsUrl(null));
};

// THUNKS
export const getRecommendedOpportunities =
  (orgId: number, shouldReset = false) =>
  async (dispatch: AppDispatch, getState: Function) => {
    const loadingId = 'getRecommendedOpportunities';
    const { opportunity, status } = getState();

    try {
      if (status.ids.includes(loadingId)) return;
      dispatch(setLoading(loadingId));

      if (shouldReset) await dispatch(resetRecommendedOpps());
      if (!shouldReset && opportunity.recommendedOpps && opportunity.recommendedOpps.length && !opportunity.nextRecommendedOppsUrl) return;

      const nextUrl = shouldReset ? undefined : opportunity.nextRecommendedOppsUrl;
      const { results, next } = await opportunityService.getOrgOpportunities(orgId, nextUrl);
      if (!results) throw new Error('No opportunities found');
      dispatch(setRecommendedOpps([...getState().opportunity.recommendedOpps, ...results]));
      dispatch(setNextRecommendedOppsUrl(next));
    } catch (err) {
      dispatch(
        addToaster({
          type: 'error',
          title: 'error_title',
          text: 'oops_smthng_went_wrong',
          autoClose: false,
        })
      );
      console.log('Error while getting recommended opportunities:', err);
    } finally {
      dispatch(setFinishLoading(loadingId));
    }
  };

export const getApprovedOpportunities =
  (orgId: number, shouldReset = false) =>
  async (dispatch: AppDispatch, getState: Function) => {
    const { opportunity } = getState();

    try {
      if (opportunity.localStatus === LOADING) {
        return;
      }
      dispatch(setLocalStatus(LOADING));

      if (shouldReset) await dispatch(resetApprovedOpps());
      if (!shouldReset && opportunity.approvedOpps && opportunity.approvedOpps.length && !opportunity.nextApprovedOppsUrl) {
        dispatch(setLocalStatus(IDLE));
        return;
      }

      const nextUrl = shouldReset ? undefined : opportunity.nextApprovedOppsUrl;
      const { results, next } = await opportunityService.getApprovedOpportunities(orgId, nextUrl);
      if (!results) throw new Error('No approved opportunities found');
      dispatch(setLocalStatus(SUCCEEDED));

      dispatch(setApprovedOpps([...getState().opportunity.approvedOpps, ...results]));

      if (next && next === opportunity.nextApprovedOppsUrl) dispatch(setNextApprovedOppsUrl(null));
      else dispatch(setNextApprovedOppsUrl(next));
    } catch (err) {
      dispatch(setLocalStatus(FAILED));
      dispatch(
        addToaster({
          type: 'error',
          title: 'error_title',
          text: 'oops_smthng_went_wrong',
        })
      );
      console.log('Error while getting approved opportunities:', err);
    }
  };

export const getJoinedAndPendingOpps =
  (orgId: number, shouldReset = false) =>
  async (dispatch: AppDispatch, getState: Function) => {
    const { opportunity } = getState();
    try {
      if (opportunity.localStatus === LOADING) {
        return;
      }
      dispatch(setLocalStatus(LOADING));

      if (shouldReset) await dispatch(resetJoinedAndPendingOpps());
      if (!shouldReset && opportunity.joinedAndPendingOpps && opportunity.joinedAndPendingOpps.length && !opportunity.nextJoinedAndPendingOppsUrl) {
        dispatch(setLocalStatus(IDLE));
        return;
      }

      const nextUrl = shouldReset ? undefined : opportunity.nextJoinedAndPendingOppsUrl;
      const { results, next, count } = await opportunityService.getUserOpportunities(orgId, nextUrl);

      dispatch(setJoinedAndPendingOpps([...getState().opportunity.joinedAndPendingOpps, ...results]));

      //  handle next url
      if (next && next === opportunity.nextJoinedAndPendingOppsUrl) dispatch(setJoinedAndPendingOppsNextUrl(null));
      else dispatch(setJoinedAndPendingOppsNextUrl(next));

      dispatch(setJoinedAndPendingOppsCount(count));

      dispatch(setLocalStatus(SUCCEEDED));
    } catch (err) {
      dispatch(setLocalStatus(FAILED));
      dispatch(
        addToaster({
          type: 'error',
          title: 'error_title',
          text: 'oops_smthng_went_wrong',
        })
      );
      console.log('Error while getting user joined and pending opportunities:', err);
    }
  };

interface OpportunityByCatagoryProps {
  orgId: number;
  catagoryId: number;
  nextUrl?: string | null;
  filters?: string;
  latitude?: number | null;
  longitude?: number | null;
}

export const getOpportunityByCatagoryId =
  ({ orgId, catagoryId, nextUrl, filters, latitude, longitude }: OpportunityByCatagoryProps, shouldReset?: boolean) =>
  async (dispatch: AppDispatch, getState: Function) => {
    const { opportunity, status } = getState();
    const loadingId = 'opportunityByCatagoryId';

    if (status.ids.includes(loadingId)) return;

    try {
      dispatch(setLoading(loadingId));
      dispatch(setLocalStatus(LOADING));

      const { results, count, next } = await opportunityService.searchOpportunityByCatagory({
        orgId,
        catagoryId,
        filters,
        latitude,
        longitude,
        nextUrl,
      });

      if (!results) {
        throw new Error('No opportunities found');
      }

      //  handle next url
      if (next && (next === opportunity.nextCatagoryOppsUrl && !shouldReset)) dispatch(setCatagoryOppsNextUrl(null));
      else dispatch(setCatagoryOppsNextUrl(next));

      const oppsToSet = shouldReset ? [...results] : [...opportunity.catagoryOpps, ...results]

      dispatch(setCatagoryOpps(oppsToSet));
      dispatch(setCatagoryOppsCount(count));

      dispatch(setFinishLoading(loadingId));
      dispatch(setLocalStatus(SUCCEEDED));
    } catch (err) {
      dispatch(setFinishLoading(loadingId));
      dispatch(setLocalStatus(FAILED));
      dispatch(
        addToaster({
          type: 'error',
          title: 'error_title',
          text: 'oops_smthng_went_wrong',
        })
      );
      console.log(`Error while getting opportunities for catagory ${catagoryId}:`, err);
    }
  };

export const getOpportunityById = (opportunityId: number) => async (dispatch: AppDispatch) => {
  const loadingId = 'getOpportunityById';
  try {
    dispatch(setLoading(loadingId));

    const opportunity = await opportunityService.getOpportunityById(opportunityId);
    dispatch(setOpp(opportunity));
    dispatch(setFinishLoading(loadingId));
  } catch (err) {
    dispatch(setFinishLoading(loadingId));
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log(`Error while getting opportunity with id: ${opportunityId}:`, err);
  }
};

export const getOpportunityContact = (contactId: number) => async () => {
  try {
    const contact = await opportunityService.getOpportunityContact(contactId);
    return contact;
  } catch (err) {
    console.log(`Error while getting opportunity contact with id: ${contactId}:`, err);
  }
};

interface leaveOpportunityProps {
  oppId: number;
  orgId: number;
  reason: number;
  reasonText?: string;
}

export const leaveOpportunity =
  ({ oppId, orgId, reason, reasonText }: leaveOpportunityProps) =>
  async (dispatch: AppDispatch, getState: Function) => {
    const { opportunity } = getState();
    const loadingId = 'leaveOpportunity';
    try {
      dispatch(setLoading(loadingId));
      await opportunityService.leaveOpportunity(oppId, orgId, reason, reasonText);

      const updateJoinedOpportunity = (opp: Opportunity) => {
        opp.user_status = 5;
        return opp;
      };

      // #####################
      // UPDATE ALL OPP ARRAYS
      dispatch(
        setJoinedAndPendingOpps(
          opportunity.joinedAndPendingOpps.filter((opp: Opportunity) => {
            if (opp.id === oppId) {
              //update count
              dispatch(setJoinedAndPendingOppsCount(opportunity.joinedAndPendingOppsCount - 1));
              return false;
            }
            return true;
          })
        )
      );
      dispatch(setCatagoryOpps(opportunity.catagoryOpps.map((opp: Opportunity) => (opp.id === oppId ? updateJoinedOpportunity({ ...opp }) : opp))));
      dispatch(setApprovedOpps(opportunity.approvedOpps.filter((opp: Opportunity) => opp.id !== oppId)));
      if (opportunity.opp?.id === oppId) dispatch(setOpp(updateJoinedOpportunity({ ...opportunity.opp })));
      // #####################

      // reseting recommended opportunities
      await dispatch(resetRecommendedOpps());
      await dispatch(getRecommendedOpportunities(orgId));
    } catch (err: any) {
      dispatch(
        addToaster({
          type: 'error',
          title: 'error_title',
          text: 'oops_smthng_went_wrong',
        })
      );
      console.log(`Error while leaving opportunity with id: ${oppId}:`, err);
      sentryService.captureException(err);
    } finally {
      dispatch(setFinishLoading(loadingId));
    }
  };

export const joinOpportunity = (oppId: number, orgId: number) => async (dispatch: AppDispatch, getState: Function) => {
  const { opportunity } = getState();
  const loadingId = 'joinOppotunity';
  try {
    dispatch(setLoading(loadingId));
    const res = await opportunityService.joinOpportunity(oppId, orgId);

    const updateJoinedOpportunity = (opp: Opportunity) => {
      opp.user_status = opp.pending_status_approval_required ? 3 : 1;
      return opp;
    };

    // getting the full opportunity from the API
    const joinedOpp = await opportunityService.getOpportunityById(oppId);

    // #####################
    // UPDATE ALL OPP ARRAYS
    dispatch(setJoinedAndPendingOpps([joinedOpp, ...opportunity.joinedAndPendingOpps]));
    dispatch(setJoinedAndPendingOppsCount(opportunity.joinedAndPendingOppsCount + 1));
    dispatch(setCatagoryOpps(opportunity.catagoryOpps.map((opp: Opportunity) => (opp.id === oppId ? updateJoinedOpportunity({ ...opp }) : opp))));
    if (!joinedOpp.pending_status_approval_required) dispatch(setApprovedOpps([joinedOpp, ...opportunity.approvedOpps]));
    if (opportunity.opp?.id === oppId) dispatch(setOpp(updateJoinedOpportunity({ ...opportunity.opp })));
    // #####################

    // reseting recommended opportunities
    await dispatch(resetRecommendedOpps());
    await dispatch(getRecommendedOpportunities(orgId));

    return res;
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log(`Error while joining opportunity with id: ${oppId}:`, err);
    sentryService.captureException(err);
  } finally {
    dispatch(setFinishLoading(loadingId));
  }
};

/* INDIVIDUAL ACTIONS */
export const removeRecommendedOppById = (oppId: number) => async (dispatch: AppDispatch, getState: Function) => {
  const { opportunity } = getState();
  dispatch(setRecommendedOpps(opportunity.recommendedOpps.filter((o: Opportunity) => o.id !== oppId)));
};

export const addRecommendedOppById = (oppId: number) => async (dispatch: AppDispatch, getState: Function) => {
  const { opportunity } = getState();
  const opp = await opportunityService.getOpportunityById(oppId);
  if (opp) dispatch(setRecommendedOpps([opp, ...opportunity.recommendedOpps]));
};

export const updateRecommendedOppStatusById = (oppId: number, status: number) => async (dispatch: AppDispatch, getState: Function) => {
  const { opportunity } = getState();
  dispatch(setRecommendedOpps(opportunity.recommendedOpps.map((o: Opportunity) => (o.id === oppId ? { ...o, user_status: status } : o))));
};

export const removeJoinedAndPendingOppsById = (oppId: number) => async (dispatch: AppDispatch, getState: Function) => {
  const { opportunity } = getState();
  dispatch(setJoinedAndPendingOpps(opportunity.joinedAndPendingOpps.filter((o: Opportunity) => o.id !== oppId)));
};

export const updateJoinedAndPendingOppStatusById = (oppId: number, status: number) => async (dispatch: AppDispatch, getState: Function) => {
  const { opportunity } = getState();
  dispatch(setJoinedAndPendingOpps(opportunity.joinedAndPendingOpps.map((o: Opportunity) => (o.id === oppId ? { ...o, status } : o))));
};

export const removeApprovedOppsById = (oppId: number) => async (dispatch: AppDispatch, getState: Function) => {
  const { opportunity } = getState();
  dispatch(setApprovedOpps(opportunity.approvedOpps.filter((o: Opportunity) => o.id !== oppId)));
};

export const addApprovedOppsById = (oppId: number) => async (dispatch: AppDispatch, getState: Function) => {
  const { opportunity } = getState();
  const opp = await opportunityService.getOpportunityById(oppId);
  if (opp) dispatch(setApprovedOpps([opp, ...opportunity.approvedOpps]));
};

/* SELECTORS */

// Other code such as selectors can use the imported `RootState` type
export const selectRecomendedOpportunities = (state: RootState) => state.opportunity.recommendedOpps;
export const selectApprovedOpportunities = (state: RootState) => state.opportunity.approvedOpps;
export const selectCatagoryOpportunities = (state: RootState) => state.opportunity.catagoryOpps;
export const selectJoinedAndPendingOpportunities = (state: RootState) => state.opportunity.joinedAndPendingOpps;
export const selectSortedJoinedAndPendingOpportunities = createSelector([selectJoinedAndPendingOpportunities], (opps) =>
  [...opps].sort((a, b) => {
    if (a.status === 3 || a.user_status === 3) return -1;
    if (b.status === 3 || b.user_status === 3) return 1;
    return 0;
  })
);

export const selectOpportunity = (state: RootState) => state.opportunity.opp;

export const selectJoinedAndPendingOpportunitiesCount = (state: RootState) => state.opportunity.joinedAndPendingOppsCount;
export const selectCatagoryOpportunitiesCount = (state: RootState) => state.opportunity.catagoryOppsCount;

export const selectCatagoryOppsNextUrl = (state: RootState) => state.opportunity.nextCatagoryOppsUrl;

export default opportunitySlice.reducer;
