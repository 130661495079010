import { Preferences } from '@capacitor/preferences';

async function save<T>(key: string, value: T): Promise<void> {
  await Preferences.set({
    key,
    value: JSON.stringify(value),
  });
}

async function load<T>(key: string): Promise<T | undefined> {
  const { value } = await Preferences.get({ key });
  return value ? JSON.parse(value) : undefined;
}

async function remove(key: string): Promise<void> {
  await Preferences.remove({ key });
}

export default {
  save,
  load,
  remove,
};
