import { storageService } from '@/modules/common/services/storage';
import heLocal from '@/locale/he.json';
export type Direction = 'ltr' | 'rtl';

const LANGUAGE = 'language';

async function getCurrentLanguage(): Promise<string> {
  const lang = ((await storageService.load(LANGUAGE)) as string) ?? 'he';
  return lang;
}

function setCurrentLanguage(lang: string): void {
  storageService.save(LANGUAGE, lang);
}

function getFullLanguageName(langCode: string): string {
  let language = '';
  switch (langCode) {
    case 'en':
      language = heLocal.fragment_select_language_english;
      break;
    case 'he':
      language = heLocal.fragment_select_language_hebrew;
      break;
    case 'ar':
      language = heLocal.fragment_select_language_arabic;
      break;
  }
  return language;
}

function getTextDirection(lang: string): Direction {
  return lang === 'he' || lang === 'ar' ? 'rtl' : 'ltr';
}

function isRtl(lang: string): boolean {
  return getTextDirection(lang) === 'rtl';
}

export const languageService = {
  getCurrentLanguage,
  setCurrentLanguage,
  getFullLanguageName,
  isRtl,
};
