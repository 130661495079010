import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import toasterSlice from './reducers/toaster.reducer';
import modalSlice from './reducers/modal.reducer';
import userReducer from './reducers/user.reducer';
import organizationReducer from './reducers/organization.reducer';
import opportunityReducer from './reducers/opportunity.reducer';
import opportunityCatagoryReducer from './reducers/opportunityCatagory.reducer';
import statusReducer from './reducers/status.reducer';
import reportReducer from './reducers/report.reducer';
import errorReducer from './reducers/error.reducer';
import authReducer from './reducers/auth.reducer';
import chatReducer from './reducers/chat.reducer';
import postReducer from './reducers/post.reducer';
import routeReducer from './reducers/route.reducer';
import commentReducer from './reducers/comment.reducer';
import configurationReducer from './reducers/configuration.reducer';
import languageReducer from './reducers/language.reducer';

export const rootReducer = {
  toaster: toasterSlice,
  modal: modalSlice,
  user: userReducer,
  organization: organizationReducer,
  opportunity: opportunityReducer,
  catagory: opportunityCatagoryReducer,
  status: statusReducer,
  report: reportReducer,
  error: errorReducer,
  auth: authReducer,
  chat: chatReducer,
  post: postReducer,
  route: routeReducer,
  comment: commentReducer,
  configuration: configurationReducer,
  language: languageReducer,
};

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
