import CloseIcon from '@/assets/icons/close_icon.svg';
import { ModalWrapper } from './ModalWrapper';
import { useEffect, useState } from 'react';

interface Props {
  title: string;
  text?: string | string[];
  buttons: React.ReactNode;
  onClose?: () => void;
  topContent?: React.ReactNode;
  className?: string;
}

export const ErrorModal = (props: Props) => {
  const [screenExists, setScreenExists] = useState(false);

  function checkForModalScreen(): boolean {
    const modals = document.body.querySelectorAll('.modal-screen');
    return modals.length > 1;
  }

  useEffect(() => {
    setScreenExists(checkForModalScreen());
  }, []);

  return (
    <ModalWrapper className={screenExists ? 'transparent' : ''}>
      <section className={`confirm-modal-container modal-container error ${screenExists ? 'box-shadow' : ''} ${props.className ? props.className : ''}`}>
        {props.onClose && <img className="confirm-close-icon modal-close-btn" src={CloseIcon} alt="close icon" onClick={props.onClose} />}
        <div className="text-content">
          {props.topContent ? props.topContent : null}
          <h3>{props.title}</h3>
          {props.text && Array.isArray(props.text) ? props.text.map((t, i) => <p key={i}>{t}</p>) : <p>{props.text}</p>}
        </div>
        <div className="buttons-wrapper">
          {props.buttons ? (
            props.buttons
          ) : (
            <button onClick={props.onClose} className="btn default">
              Ok
            </button>
          )}
        </div>
      </section>
    </ModalWrapper>
  );
};
