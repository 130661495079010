import { PushNotifications } from '@capacitor/push-notifications';
import { storageService } from './storage';
import { deviceService } from './device.service';
import { handleNotificationAction, notificationAdaptor } from './notification-action.service';


const MESSAGE_TOKEN_KEY = 'message_token';

export const NOTIFICATION_CHANNEL = {
  id: 'default',
  name: 'Tribu MOE Channel',
};

async function getStoredMessageToken(): Promise<string | null> {
  return (await storageService.load(MESSAGE_TOKEN_KEY)) ?? null;
}

function setStoredMessageToken(token: string) {
  storageService.save(MESSAGE_TOKEN_KEY, token);
}

function removeStoredMessageToken() {
  storageService.remove(MESSAGE_TOKEN_KEY);
}

async function addListeners() {
  await PushNotifications.addListener('registration', (token) => {
    console.info('Registration token: ', token.value);

    setStoredMessageToken(token.value);
    deviceService.updateDeviceInfo();
  });

  await PushNotifications.addListener('registrationError', (err) => {
    console.error('Registration error: ', err.error);
  });

  await PushNotifications.addListener('pushNotificationReceived', (notification) => {
    console.log('Push notification received: ', JSON.stringify(notification));
    const adaptedNotification = notificationAdaptor(notification);
    
    if(!adaptedNotification) return;
    handleNotificationAction(adaptedNotification);
  });

  await PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
    console.log('Push notification action performed', notification.actionId, notification.inputValue);
  });
}

async function requestPermissions(): Promise<string> {
  const { receive } = await PushNotifications.requestPermissions();
  return receive;
}

async function checkPermissions(): Promise<string> {
  const { receive } = await PushNotifications.checkPermissions();
  return receive;
}

async function getToken(): Promise<string> {
  return (await getStoredMessageToken()) ?? '';
}

async function deleteToken(): Promise<void> {
  removeStoredMessageToken();
}

async function deleteChannel(channelId: string): Promise<void> {
  PushNotifications.deleteChannel({ id: channelId });
}

//utils
async function isPermissionGranted() {
  const permissions = await checkPermissions();
  return permissions === 'granted';
}
async function isPermissionDenied() {
  const permissions = await checkPermissions();
  return permissions === 'denied';
}

async function registerNotifications() {
  let permStatus = await checkPermissions();

  if (permStatus !== 'granted') {
    permStatus = await requestPermissions();
  }

  if (permStatus !== 'granted') {
    throw new Error('User denied permissions!');
  }

  await PushNotifications.register();
}

async function unregister() {
  await unregisterLocally();
  await deleteToken();
  await PushNotifications.unregister();
}

async function unregisterLocally() {
  await deviceService.removeRegisteredDevice();
}

async function init() {
  await addListeners();
  await registerNotifications();

  await PushNotifications.createChannel(NOTIFICATION_CHANNEL);
}

export const pushNotificationService = {
  addListeners,
  requestPermissions,
  getToken,
  deleteToken,
  checkPermissions,
  isPermissionGranted,
  isPermissionDenied,
  init,
  deleteChannel,
  unregister,
  registerNotifications,
  unregisterLocally,
};
