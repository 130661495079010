import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { Comment } from '@/types';
import { postService } from '@/modules/posts/services/post.service';
import { setFinishLoading, setLoading } from './status.reducer';
import { addToaster } from './toaster.reducer';
import { selectBlockedIds } from './user.reducer';

// Define a type for the slice state
export interface CommentState {
  comments: Comment[];
  commentsCount: number;
  nextCommentUrl: string | null;
}

// Define the initial state using that type
export const initialState: CommentState = {
  comments: [],
  commentsCount: 0,
  nextCommentUrl: '',
};

// reducer functions
export const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    setComments: (state, action: PayloadAction<Comment[]>) => {
      state.comments = action.payload;
    },
    setCommentsCount: (state, action: PayloadAction<number>) => {
      state.commentsCount = action.payload;
    },
    setNextCommentUrl: (state, action: PayloadAction<string | null>) => {
      state.nextCommentUrl = action.payload;
    },
    clearComments: (state) => {
      state.comments = [];
      state.commentsCount = 0;
      state.nextCommentUrl = '';
    },
  },
});

export default commentSlice.reducer;
export const { setComments, setNextCommentUrl, setCommentsCount, clearComments } = commentSlice.actions;

export const getComments = (postId: number) => async (dispatch: any, getStatus: Function) => {
  const { comment, status } = getStatus();

  const loadingId = 'getComments';
  if (status.ids.includes(loadingId)) return;

  try {
    dispatch(setLoading(loadingId));

    if (comment.comments && comment.comments.length && !comment.nextCommentUrl) return;
    const { results, count, next } = await postService.getComments(postId, comment.nextCommentUrl);

    dispatch(setComments([...comment.comments, ...results]));
    dispatch(setCommentsCount(count));

    if (next && next === comment.nextCommentUrl) dispatch(setNextCommentUrl(null));
    else dispatch(setNextCommentUrl(next));

    return results;
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('While getting comments, error: ', err);
  } finally {
    dispatch(setFinishLoading(loadingId));
  }
};

export const addComment = (postId: number, text: string) => async (dispatch: any, getStatus: Function) => {
  const { comment: comentState, status } = getStatus();

  const loadingId = 'addComment';
  if (status.ids.includes(loadingId)) return;

  try {
    dispatch(setLoading(loadingId));

    const comment = await postService.createComment(postId, text);
    dispatch(setComments([comment, ...comentState.comments]));
    dispatch(setCommentsCount(comentState.commentsCount + 1));
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('While adding comment, error: ', err);
  } finally {
    dispatch(setFinishLoading(loadingId));
  }
};

export const removeComment = (postId: number, commentId: number) => async (dispatch: any, getStatus: Function) => {
  const { comment, status } = getStatus();

  const loadingId = 'removeComment';
  if (status.ids.includes(loadingId)) return;

  try {
    dispatch(setLoading(loadingId));

    await postService.deleteComment(postId, commentId);
    dispatch(setComments(comment.comments.filter((c: Comment) => c.pk !== commentId)));
    dispatch(setCommentsCount(comment.commentsCount - 1));
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('While removing comment, error: ', err);
  } finally {
    dispatch(setFinishLoading(loadingId));
  }
};
export const selectComments = (state: any) => state.comment.comments;
export const selectNonBlockedComments = createSelector([selectComments, selectBlockedIds], (comments, blockedIds) =>
  comments.filter((c: Comment) => !blockedIds.includes(c.user.id))
);
