import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../store';
export type Statues = 'idle' | 'loading' | 'succeeded' | 'failed';

export interface StatusState {
  ids: string[];
}
export interface StatusPayload {
  id: string;
}

// Define the initial state using that type
export const initialState: StatusState = {
  ids: [],
};

export const statusSlice = createSlice({
  name: 'status',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      if (!state.ids.includes(id)) {
        state.ids.push(id);
      }
    },
    setFinishLoading: (state, action: PayloadAction<string>) => {
      state.ids = state.ids.filter((i) => i !== action.payload);
    },
  },
});

export const { setLoading, setFinishLoading } = statusSlice.actions;

export const isStatusLoading = (state: RootState) => state.status.ids.length > 0;
export const isStatusLoadingById = (state: RootState, id: string) => state.status.ids.includes(id);

export default statusSlice.reducer;
