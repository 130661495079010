import { httpService } from '@/modules/common/services/http.service';
import { Post, Attachment } from '@/types';
import { ERRORS } from './post.errors';

async function getPosts(nextUrl?: string): Promise<{ data: Post[]; next: any }> {
  let data: Post[];
  let next: string;

  const response = await httpService.get('posts/?limit=5', { fullUrl: nextUrl ? nextUrl : '' });

  data = response.results;
  next = response.next;

  const postIds = data.map((it: any) => it.pk);
  
  if (postIds && postIds.length > 0) {
    const attachmentsResponse = await getPostsAttachments(postIds);
    if (attachmentsResponse && attachmentsResponse.length > 0) {
      for (let post of data) {
        post.attachment = attachmentsResponse.filter((attachment: any) => attachment.post == post.pk);
      }
    }
  }

  return { data, next };
}

async function getPostsAttachments(postIds: string | string[]): Promise<Attachment[]> {
  const resp = await httpService.post('posts/filter_post_attachments/', {
    data: {
      posts: postIds.toString(),
    },
  });
  return resp.results;
}

async function handleLike(postId: number, liked: boolean) {
  return await httpService.post(`posts/${postId}/${liked ? 'remove_' : ''}like/`);
}

async function getComments(postId: number, nextUrl?: string) {
  return httpService.get(`posts/${postId}/comments/`, { fullUrl: nextUrl ? nextUrl : '' });
}

async function createComment(postId: number, text: string) {
  return await httpService.post(`posts/${postId}/comments/`, { data: { text } });
}

async function deleteComment(postId: number, commentId: number) {
  return await httpService.delete(`posts/${postId}/comments/${commentId}/`);
}

async function createPostAttachment(file: File) {
  const type = file.type.includes('image') ? 1 : 2;

  return httpService.put('posts/create_media/', {
    data: {
      file,
      type,
    },
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

async function deletePostAttachment(postId: number, attachmentId: number) {
  return httpService.delete(`posts/${postId}/attachments/${attachmentId}/`);
}

async function addPostAttachment(postId: number, file: File) {
  const path = file.type.includes('image') ? 'images' : 'videos';

  return httpService.put(`posts/${postId}/${path}/add/`, {
    data: { file },
    headers: { 'Content-Type': 'multipart/form-data' },
  });
}

async function createPost(text: string, orgId: number, attachmentsIds: string[] = []) {
  return await httpService.post(`organizations/${orgId}/posts/`, { data: { caption: text, attachments: attachmentsIds } });
}

async function updatePost(text: string, postId: number, orgId: number) {
  const postResponse = await httpService.patch(`posts/${postId}/?organization=${orgId}`, { data: { caption: text } });
  if (postResponse?.attachments_count > 0) {
    const attachments = await getPostsAttachments([postId.toString()]);
    postResponse.attachment = attachments;
  }

  return postResponse;
}
async function deletePost(postId: number) {
  return await httpService.delete(`posts/${postId}/`);
}

async function getInappropritateReasons() {
  return httpService.get('posts/inappropriate_reasons/');
}

async function reportPostInappropriate(postId: number, reasons: number[]) {
  return httpService.post(`posts/${postId}/update_inappropriate_mark/`, { data: { reasons } });
}

function getErrorMessageKey(type: string) {
  switch (type) {
    case 'maxUploadImageSize':
      return ERRORS.MAX_UPLOAD_IMAGE_SIZE;
    case 'maxUploadVideoSize':
      return ERRORS.MAX_UPLOAD_VIDEO_SIZE;
    case 'uploadVideoFormat':
      return ERRORS.UPLOAD_VIDEO_FORMAT;
    case 'uploadImageFormat':
      return ERRORS.UPLOAD_IMAGE_FORMAT;
    case 'minUploadImageResolution':
      return ERRORS.MIN_UPLOAD_IMAGE_RESOLUTION_ERROR;
    case 'minUploadVideoResolution':
      return ERRORS.MIN_UPLOAD_VIDEO_RESOLUTION_ERROR;
    default:
      return '';
  }
}

export const postService = {
  getPosts,
  getComments,
  createComment,
  handleLike,
  getPostsAttachments,
  getErrorMessageKey,
  createPostAttachment,
  addPostAttachment,
  deletePostAttachment,
  createPost,
  updatePost,
  deletePost,
  deleteComment,
  getInappropritateReasons,
  reportPostInappropriate,
};
