import { BasicConfiguration, FullConfiguration } from '@/types';
import { httpService } from './http.service';
import { storageService } from './storage';
import { config } from '@/config';

const COUNTRY_CODE_KEY = 'country_code';
export const IDM_LOGIN_COUNTRY_CODES = ['IL', 'PS', 'JO', 'EG', 'LB', 'SY'].concat(config?.isDev ? ['UA'] : []);

async function getCountryCode(): Promise<string> {
  const storedCountryCode = await getStoredUserCountry();
  
  // Check if stored country code is valid
  if (storedCountryCode && !IDM_LOGIN_COUNTRY_CODES.includes(storedCountryCode)) {
    await removeStoredUserCountry();
    return await fetchAndStoreCountryCode();
  } else if (storedCountryCode) {
    return storedCountryCode; // Return valid, stored country code
  }

  // Fetch new country code if not stored or invalid
  return await fetchAndStoreCountryCode();
}

async function fetchAndStoreCountryCode(): Promise<string> {
  const { country_code: countryCode } = await getGeoInfo();
  if (IDM_LOGIN_COUNTRY_CODES.includes(countryCode)) {
    setStoredUserCountry(countryCode);
  }
  return countryCode;
}

async function getGeoInfo() {
  const res = await fetch(`https://ipwhois.pro/json/?key=Nwe8LTzSpYvAGWdg`);
  return await res.json();
}

async function getBasicAppConfiguration(): Promise<BasicConfiguration> {
  const res = await httpService.get(`configuration/basic/`, { withToken: false });
  return res;
}
async function getAppConfiguration(): Promise<FullConfiguration> {
  const res = await httpService.get(`configuration/`);
  return res;
}
async function getAndroidVersionInfo(): Promise<string> {
  const info = await httpService.get(`configuration/androidupdatejson/`);
  return info?.version;
}
async function getIOSVersionInfo(): Promise<string> {
  let randNumber = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
  let response = await fetch(`https://itunes.apple.com/lookup?bundleId=com.wetribu.tribu&rand=${randNumber}`);

  let json = await response.json();

  return (json.results as Array<any>)[0].version;
}

export const configurationService = {
  getGeoInfo,
  getCountryCode,
  getBasicAppConfiguration,
  getAppConfiguration,
  getAndroidVersionInfo,
  getIOSVersionInfo,
};

async function getStoredUserCountry() {
  return storageService.load<string | undefined>(COUNTRY_CODE_KEY);
}

function setStoredUserCountry(countryCode: string) {
  return storageService.save(COUNTRY_CODE_KEY, countryCode);
}

function removeStoredUserCountry() {
  return storageService.remove(COUNTRY_CODE_KEY);
}
