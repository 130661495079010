import { Capacitor } from '@capacitor/core';
import { httpService } from './http.service';

/* EVENTS */
export const DELETE_BUTTON_CLICK = 'delete_button_click';
export const CLICK_CONFIRM_DELETION = 'click_confirm_deletion';
export const START_MOBILE_SESSION = 'start_mobile_session';
export const END_MOBILE_SESSION = 'end_mobile_session';

export type EventName = typeof DELETE_BUTTON_CLICK | typeof CLICK_CONFIRM_DELETION | typeof START_MOBILE_SESSION | typeof END_MOBILE_SESSION;

async function postAnalyticsEvent(eventName: EventName, data: any) {
  try {
    data = { ...data, event_name: eventName, device_type: Capacitor.getPlatform() };

    return httpService.post('analytics/events/', { data: { events: [data] } });
  } catch (err: any) {
    console.error('postAnalyticsEvent error:', err);
  }
}

export const analyticsService = {
  postAnalyticsEvent,
};
