import { Intercom } from '@capacitor-community/intercom';
import { languageService } from '../language.service';
import { Capacitor } from '@capacitor/core';
import { User, Organization } from '@/types';
import { getUserDetails } from './util';

async function initializeIntercom() {
  Intercom.registerUnidentifiedUser();
  Intercom.hideLauncher();
}

async function showIntercom(user?: User | null, org?: Organization | null) {
  const intercomConfig = await getIntercomNativeConfig(user, org);
  if (user) {
    Intercom.updateUser(intercomConfig);
  } else {
    Intercom.registerUnidentifiedUser();
  }
  Intercom.displayMessenger();
}

// CONFIG
async function getIntercomNativeConfig(user?: User | null, org?: Organization | null) {
  const lang = await languageService.getCurrentLanguage();
  const platform = Capacitor.getPlatform();

  const defaultConfig = {
    languageOverride: lang,
    customAttributes: {
      'ORG Type': 'MOE',
      Platform: platform,
    },
  };

  if (!user || !org) {
    return defaultConfig;
  }

  const userDetails = await getUserDetails(user, org);

  return {
    ...defaultConfig,
    userId: user.pk.toString(),
    email: user.email,
    name: `${user.first_name} ${user.last_name}`,
    phone: user.phone_number,
    customAttributes: {
      ...defaultConfig.customAttributes,
      'ORG Name': userDetails['ORG Name'],
      'ORG ID': userDetails['ORG ID'],
      Role: userDetails.Role,
    },
  };
}

async function shutdownIntercom() {
  Intercom.logout();
}

export default { initializeIntercom, showIntercom, shutdownIntercom };
