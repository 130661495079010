import { storageService } from '@/modules/common/services/storage';
import { httpService } from '@/modules/common/services/http.service';
import { User } from '@/types';
import { organizationService } from '@/modules/organization/services/organization.service';
import { config } from '@/config';
import { pushNotificationService } from '@/modules/common/services/push-notification.service';
import { intercomService } from '@/modules/common/services/intercom';
import { Capacitor } from '@capacitor/core';

const USER_TOKEN = 'user_token';
const USER = 'user';

export type ErrorTitleMap = {
  [key: string]: { title: string; message: string };
};

export const ERROR_TITLE_MAP = {
  '401StudentDoesNotExist': {
    title: 'student_does_not_exist_login_error_title',
    message: '',
  },
  '403UserDisabled': {
    title: 'account_deleted_login_error_title',
    message: 'account_deleted_login_error_description',
  },
};

async function loginMoeUser(userToken: string) {
  const data = {
    user_token: userToken,
    provider: 'edu',
  };

  try {
    return httpService.post('auth/social_login/', { data });
  } catch (error) {
    console.log(error);
  }
}

async function logout() {
  try {
    intercomService.shutdownIntercom();

    if (Capacitor.isNativePlatform()) {
      // can only unregister push-notifications in mobile
      await pushNotificationService.unregister();
    }

    await httpService.post('auth/logout/');
    clearData();
  } catch (error) {
    console.log(error);
  } finally {
    IDMLogout();
  }
}

async function logoutClientOnly() {
  /*
   * This function is to be called after deleting the user account
   * to avoid the API logout call
   * Otherwise, we will be missing the token when sending the analytics event
   */
  try {
    intercomService.shutdownIntercom();
    pushNotificationService.deleteToken();
    clearData();
  } catch (err) {
    console.log(err);
  } finally {
    IDMLogout();
  }
}

async function loginEmailUser(data: { username: string; password: string }) {
  return httpService.post('auth/login/', {
    data,
  });
}

async function getWSToken() {
  try {
    const res = await httpService.get('auth/get_ws_token/');
    return res.key;
  } catch (error) {
    console.log(error);
  }
}

async function IDMLogout() {
  /*
   * IDM logout need to remove the cookies from the IDM page
   * and redirect to the welcome page
   * in local state we need to open the IDM logout page in new tab because it can't redirect back to the webapp
   */
  try {
    if (!config.isFrontendLocal) {
      const logoutPath = config.IDMLogoutUrl + window.location.origin + '/welcome';
      window.location.replace(logoutPath);
    } else {
      window.open('https://is.remote.education.gov.il/nidp/jsp/logoutSuccess.jsp', '_blank');
      window.location.replace(window.location.origin + '/welcome');
    }
  } catch (err: any) {
    console.log(err.message);
  }
}

async function fakeLogin() {
  return httpService.post('auth/social_login_backdoor/', {
    data: {
      social_id: '331493270',
      provider: 'edu',
    },
    withToken: false,
  });
}

function clearData() {
  storageService.remove(USER_TOKEN);
  storageService.remove(USER);
  organizationService.clearOrganization();
}

async function getUser(): Promise<User | undefined> {
  return storageService.load(USER);
}
async function saveUser(user: User): Promise<void> {
  return storageService.save(USER, user);
}
async function saveUserToken(token: string): Promise<void> {
  return storageService.save(USER_TOKEN, token);
}
async function getUserToken(): Promise<string | undefined> {
  return storageService.load(USER_TOKEN);
}

export const authService = {
  getUser,
  saveUserToken,
  saveUser,
  getUserToken,
  loginMoeUser,
  logout,
  getWSToken,
  IDMLogout,
  clearData,
  loginEmailUser,
  logoutClientOnly,
  fakeLogin,
};
