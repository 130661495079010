import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { postService } from '@/modules/posts/services/post.service';
import { setFinishLoading, setLoading } from './status.reducer';
import { Post } from '@/types';
import { AppDispatch, RootState } from '../store';
import { addToaster } from './toaster.reducer';
import { selectBlockedIds } from './user.reducer';

// Define a type for the slice state
export interface PostState {
  posts: Post[];
  nextPostUrl?: string | null;
  inappropriateReasons: any[];
  isLoadingMorePosts: boolean;
}

// Define the initial state using that type
export const initialState: PostState = {
  posts: [],
  inappropriateReasons: [],
  nextPostUrl: null,
  isLoadingMorePosts: false,
};

// reducer functions
export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    setPosts: (state, action: PayloadAction<Post[]>) => {
      state.posts = action.payload;
    },
    setNextPostUrl: (state, action: PayloadAction<string | null>) => {
      state.nextPostUrl = action.payload;
    },
    setInappropriateReasons: (state, action) => {
      state.inappropriateReasons = action.payload;
    },
    setIsLoadingMorePosts: (state, action: PayloadAction<boolean>) => {
      state.isLoadingMorePosts = action.payload;
    },
    setClearPosts: (state) => {
      state.posts = [];
      state.nextPostUrl = null;
      state.isLoadingMorePosts = false;
    },
  },
});

export const { setPosts, setNextPostUrl, setInappropriateReasons, setIsLoadingMorePosts, setClearPosts } = postSlice.actions;

export const getPosts = () => async (dispatch: AppDispatch, getState: Function) => {
  const { post } = getState();

  try {
    if (post.posts && post.posts.length && !post.nextPostUrl) return;
    if (post.isLoadingMorePosts) return;
    dispatch(setIsLoadingMorePosts(true));


    const { data, next } = await postService.getPosts(post.nextPostUrl);
    if (!data || !data.length) throw new Error('No posts found');

    dispatch(setPosts([...post.posts, ...data]));

    if (next && next === post.nextPostUrl) dispatch(setNextPostUrl(null));
    else dispatch(setNextPostUrl(next));
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('Error while getting posts:', err);
  } finally {
    dispatch(setIsLoadingMorePosts(false));
  }
};

export const createPost = (text: string, orgId: number, files: File[]) => async (dispatch: AppDispatch, getState: Function) => {
  const { post: postState } = getState();
  const loadingId = 'createPost';
  try {
    if (!orgId) return;

    dispatch(setLoading(loadingId));
    const attachmentIds = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const attchResp = await postService.createPostAttachment(file);
      attachmentIds.push(attchResp.id);
    }

    const post = await postService.createPost(text, orgId, attachmentIds);
    post.attachment = await postService.getPostsAttachments(post.pk);

    dispatch(setPosts([post, ...postState.posts]));
    return post;
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: err.message ? err.message : 'oops_smthng_went_wrong',
      })
    );
    console.log('Error while creating post:', err);
  } finally {
    dispatch(setFinishLoading(loadingId));
  }
};
export const updatePost = (text: string, orgId: number, files: File[], fileIdsToDelete: number[], postId: number) => async (dispatch: AppDispatch, getState: Function) => {
  const { post: postState } = getState();
  const loadingId = 'updatePost';
  try {
    if (!orgId) return;
    dispatch(setLoading(loadingId));

    // check if there are fles to be deleted
    if (fileIdsToDelete && fileIdsToDelete.length > 0) {
      await Promise.all(fileIdsToDelete.map((id) => postService.deletePostAttachment(postId, id)));
    }

    // add new attachments
    await Promise.all(files.map((file) => postService.addPostAttachment(postId, file)));

    const post = await postService.updatePost(text, postId, orgId);

    const updatedPosts = postState.posts.map((p: Post) => (p.pk === postId ? post : p));
    dispatch(setPosts(updatedPosts));
    return post;
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('Error while updating post:', err);
  } finally {
    dispatch(setFinishLoading(loadingId));
  }
};

export const removePost = (postId: number) => async (dispatch: AppDispatch, getState: Function) => {
  const { post: postState } = getState();
  const loadingId = 'removePost';
  try {
    dispatch(setLoading(loadingId));
    await postService.deletePost(postId);
    dispatch(setPosts(postState.posts.filter((p: Post) => p.pk !== postId)));
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('Error while removing post:', err);
  } finally {
    dispatch(setFinishLoading(loadingId));
  }
};

export const handleLikePost = (postId: number, isLiked: boolean) => async (dispatch: AppDispatch, getState: Function) => {
  const { post: postState } = getState();
  try {
    postService.handleLike(postId, isLiked);

    const updatePost = (postToUpdate: Post) => {
      return { ...postToUpdate, liked: !isLiked, likes_count: isLiked ? postToUpdate.likes_count - 1 : postToUpdate.likes_count + 1 };
    };

    const updatedPosts = postState.posts.map((p: Post) => (p.pk === postId ? updatePost(p) : p));
    dispatch(setPosts(updatedPosts));
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('Error while liking post:', err);
  }
};

export const updatePostCommentCount = (postId: number, diff: number) => async (dispatch: AppDispatch, getState: Function) => {
  const { post: postState } = getState();
  try {
    const updatePost = (postToUpdate: Post) => {
      return { ...postToUpdate, comments_count: postToUpdate.comments_count + diff };
    };

    const updatedPosts = postState.posts.map((p: Post) => (p.pk === postId ? updatePost(p) : p));
    dispatch(setPosts(updatedPosts));
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('Error while updating post comment count:', err);
  }
};

export const reportInappropriate = (postId: number, reasons: number[]) => async (dispatch: AppDispatch, getState: Function) => {
  const { post: postState } = getState();
  const loadingId = 'reportInappropriate';
  try {
    dispatch(setLoading(loadingId));

    const updatePost = (postToUpdate: Post) => {
      return { ...postToUpdate, inappropriate_mark: !postToUpdate.inappropriate_mark };
    };

    await postService.reportPostInappropriate(postId, reasons);

    const updatedPosts = postState.posts.map((p: Post) => (p.pk === postId ? updatePost(p) : p));
    dispatch(setPosts(updatedPosts));
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('Error while reporting inappropriate post:', err);
  } finally {
    dispatch(setFinishLoading(loadingId));
  }
};

export const getInappropriateReasons = () => async (dispatch: AppDispatch) => {
  try {
    const { results } = await postService.getInappropritateReasons();
    dispatch(setInappropriateReasons(results));
  } catch (err: any) {
    dispatch(
      addToaster({
        type: 'error',
        title: 'error_title',
        text: 'oops_smthng_went_wrong',
      })
    );
    console.log('Error while getting inappropriate reasons:', err);
  }
};

export default postSlice.reducer;
export const selectPosts = (state: RootState) => state.post.posts;
export const selectNonBlockedPosts = createSelector([selectPosts, selectBlockedIds], (posts, blockedIds) => posts.filter((p) => !blockedIds.includes(p.user)));
export const selectIsLoadingMorePosts = (state: RootState) => state.post.isLoadingMorePosts;
