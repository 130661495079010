import { AppUpdate } from '@capawesome/capacitor-app-update';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { addToaster, selectTostars } from '@/store/reducers/toaster.reducer';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from './ConfirmModal';
import { eventBus, ACTIONS } from '@/modules/common/services/event-bus.service';
import { App } from '@capacitor/app';
import { configurationService } from '../services/configuration.service';
import { Capacitor } from '@capacitor/core';

export const MobileUpdateController = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const toasters = useAppSelector(selectTostars);
  const configuration = useAppSelector((state) => state.configuration.configuration);

  const forceUpdatePopoupShown = useRef(false);
  const mobileUpdatePopoupShown = useRef(false);

  const [showUpdateModal, setShowUpdateModal] = useState(false);


  const getCurrentAppVersion = async () => {
    const info = await App.getInfo();
    return info.version;
  };

  const compareStringsIsbigger = (a: string, b: string) => {
    return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' }) < 0;
  };

  const isCurrVersionUpdated = async () => {
    let storeVersion = '0.0.0';

    const currVersion = await getCurrentAppVersion();

    const platform = Capacitor.getPlatform();
    if (platform === 'android') {
      storeVersion = await configurationService.getAndroidVersionInfo();
    } else if (platform === 'ios') {
      storeVersion = await configurationService.getIOSVersionInfo();
    }

    return compareStringsIsbigger(currVersion, storeVersion);
  };
  
  const openAppStore = async () => {
    await AppUpdate.openAppStore({ country: 'IL' });
  };

  const handleForceUpdateNotification = () => {
    if (forceUpdatePopoupShown.current) return;

    if (configuration && 'mobile_app_force_update_moe' in configuration && configuration.mobile_app_force_update_moe) {
      setShowUpdateModal(true);
      forceUpdatePopoupShown.current = true;
    }
  };

  const handleMobileUpdateNotification = () => {
    if (mobileUpdatePopoupShown.current) return;

    if (configuration && 'mobile_app_update_moe' in configuration && configuration.mobile_app_update_moe) {
      const title = t('new_version_update');

      const isMessageShown = !!toasters.find((toaster) => toaster.text === title)

      if (!isMessageShown) {
        dispatch(addToaster({ text: title, type: 'success', autoClose: false }));
        mobileUpdatePopoupShown.current = true;
      }
    }
  };

  const checkForUpdate = async () => {
    // check if the version is updated
    const isVersionUpdated = await isCurrVersionUpdated();
    if (isVersionUpdated) return;

    // if the version is not updated, check if we need to show the update modal
    // the handlers will check if the popus were already shown
    // and if they should be shown, based on the configuration
    handleForceUpdateNotification();
    handleMobileUpdateNotification();
  };

  useEffect(() => {
    checkForUpdate();
  }, [configuration, toasters]);

  useEffect(() => {
    eventBus.on(ACTIONS.APP_IS_ACTIVE, checkForUpdate);

    return () => {
      eventBus.off(ACTIONS.APP_IS_ACTIVE, checkForUpdate);
    };
  }, []);

  return showUpdateModal ? (
    <ConfirmModal
      title={t('version_alert_update_title')}
      text={t('version_alert_update_body')}
      buttons={
        <>
          <button onClick={openAppStore} className="btn default">
            {t('version_alert_update_button')}
          </button>
        </>
      }
      onClose={() => setShowUpdateModal(false)}
      buttonsClassName="row"
      hideCloseBtn={true}
    />
  ) : null;
};
