import { WebSocketContext } from '@/contexts/WebSocketContext';
import { initSocket, WebSocketClient, WebSocketClientConfig } from '@/modules/common/services/ws.service';
import { useAppSelector } from '@/store/hooks';
import { selectIsLoggedIn } from '@/store/reducers/auth.reducer';
import { useEffect, useState } from 'react';

export const WebSocketProvider: React.FC<{ config?: WebSocketClientConfig; children?: React.ReactNode }> = ({ children, config }) => {
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const [client, setClient] = useState<WebSocketClient | null>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);

  useEffect(() => {
    if(!isLoggedIn) return;
    let active = true;
    // Open the WebSocket connection
    const setupSocket = async () => {
      const wsClient = await initSocket({
        ...config,
        onConnectCb: () => setIsConnected(true),
      });
      if (active) {
        setClient(wsClient);
      }
    };

    setupSocket();

    // Close the WebSocket connection when the component unmounts
    return () => {
      active = false; // Avoid updating the state after the component is unmounted
      if (client) {
        client.disconnect();
        setIsConnected(false);
      }
    };
  }, [config, isLoggedIn]);

  return <WebSocketContext.Provider value={{ client, isConnected }}>{children}</WebSocketContext.Provider>;
};
