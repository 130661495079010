import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { authService } from '../services/auth.service';
import { selectIsLoggedIn, setIsLoggedIn } from '@/store/reducers/auth.reducer';
import { useEffect } from 'react';

interface Props {
  setIsCheckedForToken: (isCheckedForToken: boolean) => void;
}

export const LoginFakeUser = ({ setIsCheckedForToken }: Props) => {
  const dispatch = useAppDispatch();

  const isLoggedIn = useAppSelector(selectIsLoggedIn);

  const doFakeLogin = async () => {
    const resp: any = await authService.fakeLogin();
    if (resp.key && resp.user) {
      authService.saveUserToken(resp.key);
      authService.saveUser(resp.user);
      dispatch(setIsLoggedIn(true));
    } else {
      dispatch(setIsLoggedIn(false));
    }

    setIsCheckedForToken(true);
    if (window.location.pathname === '/welcome') window.location.replace('/home/');
  };

  useEffect(() => {
    if (isLoggedIn) return;
    doFakeLogin();
  }, [isLoggedIn]);

  return null;
};
